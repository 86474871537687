import React, { useState, useEffect } from "react";
import { Button, Image, Modal, Header, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function getIdLinguaFromIso(codIso) {
  if (codIso === undefined) codIso = "IT";
  switch (codIso.toUpperCase()) {
    case "IT":
      return 1;
    case "DE":
      return 2;
    case "EN":
      return 3;
    case "GB":
      return 3;
    case "FR":
      return 4;
    case "ES":
      return 5;
    case "PL":
      return 6;
    case "RU":
      return 7;
    case "NL":
      return 8;
    case "CN":
      return 9;
    case "CZ":
      return 10;
    case "HR":
      return 11;
    case "PO":
      return 12;
    case "BR":
      return 13;
    case "AR":
      return 14;
    case "GR":
      return 15;
    case "DK":
      return 16;
    case "HU":
      return 17;
    case "SK":
      return 18;
    case "SE":
      return 19;
    case "NO":
      return 20;
    case "FI":
      return 21;
    default:
      return 1;
  }

  return 1;
}

const LingueMobileButton = ({ appData, datiScuola, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  return (
    <div class="lingue_mobile_container">
      <Modal
        style={{ textAlign: "center" }}
        basic
        centered
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        size="small"
        open={open}
        trigger={
          <Button className="lingue_mobile_button">
            <Image
              src={require(`../assets/images/flags/noborder/${getIdLinguaFromIso(
                appData.lang
              )}.jpg`)}
              alt={"it"}
            ></Image>
          </Button>
        }
      >
        <Header>
          <Icon name="language"></Icon>
          {t("scelta_lingua")}
        </Header>
        <Modal.Content>
          {datiScuola.lingue.map((x) => (
            <Image
              src={require(`../assets/images/flags/noborder/${getIdLinguaFromIso(
                x.codiceIso.toUpperCase()
              )}.jpg`)}
              alt={x.codiceIso.toLowerCase()}
              onClick={() => {
                const newCode = x.codiceIso.toLowerCase();
                if (location.pathname.toLowerCase().includes("checkin")) {
                  history.push(`/${newCode}/${appData.slug}/checkin`);
                  history.go(0);
                } else {
                  history.push(`/${newCode}/${appData.slug}`);
                }
                setOpen(false);
              }}
            ></Image>
          ))}
        </Modal.Content>
      </Modal>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LingueMobileButton);
