import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";

export const setUpSignalRConnection = async (onPong) => {
  const connection = new HubConnectionBuilder()
    .withUrl("/fastBookingHub")
    .withAutomaticReconnect()
    .build();

  connection.on("pong", onPong);

  try {
    await connection.start();
  } catch (err) {
    console.log(err);
  }

  if (connection.state === HubConnectionState.Connected) {
  }

  return connection;
};

export const setUpSignalRConnectionAck = async (onAck) => {
  const connection = new HubConnectionBuilder()
    .withUrl("/fastBookingHub")
    .withAutomaticReconnect()
    .build();

  connection.on("ack", (message) => {
    onAck(message);
  });

  try {
    await connection.start();
  } catch (err) {
    console.log(err);
  }

  if (connection.state === HubConnectionState.Connected) {
  }

  return connection;
};

export const setUpSignalRConnectionAxepta = async (onAck, onNack, onOk) => {
  const connection = new HubConnectionBuilder()
    .withUrl("/axeptaHub")
    .withAutomaticReconnect()
    .build();

  connection.on("ack", (message) => {
    onAck(message);
  });

  connection.on("nack", (message) => {
    onNack(message);
  });

  connection.on("ok", (message) => {
    onOk(message);
  });

  try {
    await connection.start();
  } catch (err) {
    console.log(err);
  }

  if (connection.state === HubConnectionState.Connected) {
  }

  return connection;
};
