import { getBaseUrl } from "./apiUtils";

const baseUrl = getBaseUrl(); //"https://localhost:44354/api/";

async function CheckoutApiCall(orderData) {}

export async function CheckoutCarrello(orderData) {
  const res = await fetch(`${baseUrl}booking/checkout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(orderData),
  });

  return res.json();
}

export async function GetOrderDetails(idScuola, orderId) {
  const res = await fetch(
    `${baseUrl}checkout/datiTransazione?idScuola=${idScuola}&orderId=${orderId}`
  );
  return res.json();
}

export async function CheckTrSparkasse(codScuola, orderId, controlCode) {
  const res = await fetch(
    `${baseUrl}sparkasse/${codScuola}/ack?orderId=${orderId}&controlCode=${controlCode}`
  );

  return res;
}

export async function RichiediAckForzato(codScuola, orderId) {
  const res = await fetch(
    `${baseUrl}phoenix/${codScuola}/ack?Order_id=${orderId}&Transaction_ID=${makeidTr(
      15
    )}`,
    {
      method: "GET",
    }
  );

  return res;
}

function makeidTr(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export async function SetAxeptaConnectionId(
  codScuola,
  idScuola,
  orderId,
  connId
) {
  const res = await fetch(
    `${baseUrl}axepta/${codScuola}/setConnId?idScuola=${idScuola}&orderId=${orderId}&connId=${connId}`,
    {
      method: "POST",
    }
  );

  return res;
}
