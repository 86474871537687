import { getBaseUrl } from "./apiUtils";

const baseUrl = getBaseUrl();

export async function CheckIn(clienteData) {
  const res = await fetch(`${baseUrl}booking/checkin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(clienteData),
  });

  return res;
  // return res.json();
}
