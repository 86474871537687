import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function privateReducer(
  state = initialState.privateReducer,
  action
) {
  switch (action.type) {
    // case types.LOAD_DISPONIBILITA_SUCCESS:
    //   return { ...state, disponibilita: action.disp };
    // case types.LOAD_DISPONIBILITA_FAST_SUCCESS:
    //   return { ...state, disponibilitaFast: action.disp.oreDisponibili };
    // case types.LOAD_CALENDARIOINFO_SUCCESS:
    //   return { ...state, calendarioInfo: action.info };
    // case types.LOAD_FASTBOOKING_INFO_SUCCESS:
    //   return { ...state, fastBookingInfo: action.info };
    case types.APP_CLEAR:
      return { disponibilita: [], disponibilitaFast: [], fastBookingInfo: {} };
    default:
      return state;
  }
}
