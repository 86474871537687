import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Segment, Header } from "semantic-ui-react";
import TestataPagina from "../common/TestataPagina";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as appActions from "../../redux/actions/appActions";
import { useTranslation } from "react-i18next";

function TransactionKo({
  loadSchoolData,
  appData,
  setSlug,
  setLang,
  codScuola,
  datiScuola,
  lang,
  ...props
}) {
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);

  useEffect(() => {
    if (codScuola !== appData.slug || lang !== appData.lang) {
      loadSchoolData(codScuola, lang);
      setSlug(codScuola);
      setLang(lang);
    }
  }, []);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  return (
    <Container>
      <div className="head-offset"></div>
      <TestataPagina
        titolo={t("PAGAMENTO")}
        sottoTitolo={t("pagamento_no_buon_fine")}
        icon="credit card"
        style={{ backgroundColor: "red" }}
      ></TestataPagina>
      <Segment className="padding_5_lr">
        <Segment inverted color="red">
          <Header as="h3" textAlign="center">
            {t("PAGAMENTO_NO_BUON_FINE")}
          </Header>
        </Segment>
      </Segment>
    </Container>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadSchoolData: scuolaActions.loadSchoolData,
  setSlug: appActions.setSlug,
  setLang: appActions.setLang,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionKo);
