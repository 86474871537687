import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Divider, Segment } from "semantic-ui-react";

const Prezzo2 = ({
  numGiorniScelti,
  corso,
  sessioniCorso,
  sessioneSelezionata,
  numAllievi,
  datiScuola,
  primaData,
  ...props
}) => {
  const [listinoInUso, setListinoInUso] = useState({});
  const { t } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    console.log("Prezzo2 useEffect");
    const listino =
      corso.tipoVenditaWeb === 1
        ? sessioniCorso.sessioni[0].listinoPacchetto
        : sessioneSelezionata.listino;

    let sessioniEffettive = 0;
    if (corso.tipoVenditaWeb === 1) {
      sessioniEffettive = numGiorniScelti;
    } else {
      //recupero l'index della prima data
      let startDayIndex = 0;
      for (let i = 0; i < sessioneSelezionata.listaDateEffettive.length; i++) {
        if (
          primaData.format("YYYY-MM-DD") ===
          moment(sessioneSelezionata.listaDateEffettive[i]).format("YYYY-MM-DD")
        ) {
          startDayIndex = i;
          break;
        }
      }

      for (let i = 0; i < numGiorniScelti; i++) {
        //conto e sommo le sessioni della giornata
        for (let r = 0; r < sessioneSelezionata.righe.length; ++r) {
          if (
            moment(sessioneSelezionata.righe[r].data).format("DD/MM/YYYY") ===
            moment(
              sessioneSelezionata.listaDateEffettive[startDayIndex + i]
            ).format("DD/MM/YYYY")
            //primaData.clone().add(i, "days").format("DD/MM/YYYY")
          ) {
            sessioniEffettive++;
          }
        }
      }
    }

    setListinoInUso(
      listino.find((x) => parseInt(sessioniEffettive) === x.numGiorni) ?? {}
    );
  }, [corso, numGiorniScelti, primaData, sessioneSelezionata, sessioniCorso]);

  if (numGiorniScelti < 1 || Object.keys(listinoInUso).length === 0)
    return <></>;

  return (
    <Segment
      textAlign={(datiScuola.tipoBanca_2 ?? 0) > 0 ? "left" : "right"}
      basic
      style={{ marginTop: "0px", paddingLeft: "0px" }}
    >
      <Divider />
      {listinoInUso.prezzo < listinoInUso.prezzoListino && (
        <>
          <div className="riga-prezzo">
            <span className="prezzo_parziale label-prezzo">
              {t("prezzo_listino")}
            </span>
            <span>
              <span className="prezzo_parziale label-prezzo">
                {numAllievi} x
              </span>
              <span className="prezzo_parziale prezzo-barrato">
                {datiScuola.valuta} {listinoInUso.prezzoListino.toFixed(2)}
              </span>
            </span>
          </div>
        </>
      )}
      <div className="riga-prezzo">
        <span className="prezzo_parziale label-prezzo">
          {t("prezzo_online")}
        </span>
        <span>
          <span className="prezzo_parziale label-prezzo">{numAllievi} x</span>
          <span className="prezzo_parziale">
            {datiScuola.valuta} {listinoInUso.prezzo.toFixed(2)}
          </span>
        </span>
      </div>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="riga-prezzo"
      >
        <span className="prezzo_totale label-prezzo">{t("totale")}</span>

        <span className="prezzo_totale">
          {datiScuola.valuta} {(numAllievi * listinoInUso.prezzo).toFixed(2)}
        </span>
      </div>
      {listinoInUso.prezzo !== listinoInUso.prezzoPag && (
        <>
          <Divider />
          <div className="riga-prezzo">
            <span className="prezzo_parziale label-prezzo">
              {t("acconto_da_pagare")}
            </span>

            <span className="prezzo_parziale">
              {datiScuola.valuta}{" "}
              {(numAllievi * listinoInUso.prezzoPag).toFixed(2)}
            </span>
          </div>
          <div className="riga-prezzo">
            <span className="prezzo_parziale label-prezzo">{t("saldo")}</span>

            <span className="prezzo_parziale">
              {datiScuola.valuta}{" "}
              {(
                numAllievi * listinoInUso.prezzo -
                numAllievi * listinoInUso.prezzoPag
              ).toFixed(2)}
            </span>
          </div>
        </>
      )}
      <Divider />
    </Segment>
  );
};

export default Prezzo2;
