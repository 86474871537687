import React from "react";
import { connect } from "react-redux";
import {
  Segment,
  TransitionablePortal,
  Header,
  Icon,
  Divider,
  Button,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import useWindowSize from "../../utils/useWindowSize";

function TestoPrivacy({ datiScuola, t, ...props }) {
  const windowSize = useWindowSize();

  if (!datiScuola) return null;
  if (Object.keys(datiScuola).length === 0) return null;

  return (
    <TransitionablePortal open={props.isOpen} onClose={props.onClose}>
      <>
        <Segment
          basic
          style={{
            position: "fixed",
            height: "90%",
            zIndex: "100000",
          }}
          textAlign="center"
          className="riquadroinfo"
        >
          <Segment
            style={{
              width: windowSize.width > 600 ? "70%" : "95%",
              overflow: "auto",
              height: "100%",
              border: `5px ${
                window.getComputedStyle(document.querySelector("body"))
                  .backgroundColor
              } solid`,
              margin: "0 auto",
            }}
            className="riquadro-condizioni-e-privacy"
          >
            <Header as="h2" className="skiwork-titolo">
              <Header.Content style={{ width: "100%" }}>
                <span>{t("informativa_trattamento")}</span>
                <span style={{ float: "right" }}>
                  <Icon
                    name="x"
                    size="large"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.onClose();
                    }}
                  ></Icon>
                </span>
              </Header.Content>
            </Header>
            <Divider />
            {props.numRichiesta === undefined && (
              <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: datiScuola.testoPrivacy.replace(/\n/g, "<br>"),
                }}
              ></p>
            )}
            {props.numRichiesta === 2 && (
              <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: datiScuola.testoPrivacy2.replace(/\n/g, "<br>"),
                }}
              ></p>
            )}
            {props.numRichiesta === 3 && (
              <p
                style={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{
                  __html: datiScuola.testoPrivacy3.replace(/\n/g, "<br>"),
                }}
              ></p>
            )}
            <Button
              color="red"
              circular
              onClick={() => {
                props.onClose();
              }}
              icon="close"
              size="big"
              style={{
                position: "fixed",
                bottom: "50px",
                marginLeft: "-18px",
              }}
            ></Button>
          </Segment>
        </Segment>
      </>
    </TransitionablePortal>
  );
}

TestoPrivacy.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    datiScuola: state.scuolaReducer,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TestoPrivacy);
