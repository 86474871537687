import { handleResponse, handleError, getBaseUrl } from "./apiUtils";

const baseUrl = getBaseUrl(); //"https://localhost:44354/api/";

export async function getCalendarioInfo(
  idScuola,
  idMaestro,
  idLingua,
  idSpecialita,
  sesso,
  soloOfferte,
  soloNormali
) {
  return fetch(
    `${baseUrl}scuole/${idScuola}/private/calendarioinfo?idMaestro=${idMaestro}&idLingua=${idLingua}&idSpecialita=${idSpecialita}&sesso=${sesso}&soloOfferte=${soloOfferte}&soloNormali=${soloNormali}`
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getDisponibilita(
  idScuola,
  idAgenzia,
  data,
  idMaestro,
  idLingua,
  idSpecialita,
  sesso,
  soloOfferte,
  soloNormali
) {
  return fetch(
    `${baseUrl}scuole/${idScuola}/private/disponibilita?data=${encodeURI(
      data
    )}&idMaestro=${idMaestro}&idAgenzia=${idAgenzia}&idLingua=${idLingua}&idSpecialita=${idSpecialita}&sesso=${sesso}&soloOfferte=${soloOfferte}&soloNormali=${soloNormali}`
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getDisponibilitaFast(
  idScuola,
  data,
  idLingua,
  idSpecialita,
  lingua,
  codScuola
) {
  return fetch(
    `${baseUrl}scuole/${idScuola}/fastbooking?data=${encodeURI(
      data
    )}&idSpecialita=${idSpecialita}&idLingua=${idLingua}&lingua=${lingua}&codScuola=${codScuola}`
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getFastBookingInfo(idScuola, codScuola) {
  return fetch(
    `${baseUrl}scuole/${idScuola}/fastbooking/info?codScuola=${codScuola}`
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function PingFastBooking(idScuola, codScuola, idConnessione) {
  console.log("PING!");
  return fetch(
    `${baseUrl}scuole/${idScuola}/fastbooking/ping?connectionId=${idConnessione}&codScuola=${codScuola}`,
    { method: "POST" }
  )
    .then(handleResponse)
    .catch(handleError);
}

// export async function RequestAckFastBooking(
//   idScuola,
//   codScuola,
//   idConnessione,
//   data,
//   dalle,
//   alle,
//   idZona,
//   idSpecialita,
//   idMaestro
// ) {
//   return fetch(
//     `${baseUrl}scuole/${idScuola}/fastbooking/requestack?connectionId=${idConnessione}&codScuola=${codScuola}&data=${encodeURI(
//       data
//     )}&dalle=${dalle}&alle=${alle}&idZona=${idZona}&idSpecialita=${idSpecialita}&idMaestro=${idMaestro}`,
//     { method: "POST" }
//   )
//     .then(handleResponse)
//     .catch(handleError);
// }

export async function RequestAckFastBooking(
  idScuola,
  codScuola,
  idConnessione,
  listaOre
) {
  return fetch(
    `${baseUrl}scuole/${idScuola}/fastbooking/requestack?connectionId=${idConnessione}&codScuola=${codScuola}`,
    {
      method: "POST",
      body: JSON.stringify(listaOre),
      headers: { "Content-Type": "application/json" },
    }
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getMaestri(idScuola) {
  return fetch(`${baseUrl}scuole/${idScuola}/maestri`)
    .then(handleResponse)
    .catch(handleError);
}
