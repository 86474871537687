import React from "react";
import { TransitionablePortal, Segment } from "semantic-ui-react";
import CartMain from "../cart/CartMain";

function CartPortal(props) {
  function handleAddLezione() {
    props.onClose();
  }

  return (
    <TransitionablePortal
      transition={{ transition: "fly left", duration: 500 }}
      open={props.open}
      onClose={props.onClose}
    >
      <Segment
        basic
        style={{
          position: "fixed",
          height: "99%",
          zIndex: "100000",
        }}
      >
        <Segment
          style={{
            width: "80%",
            overflow: "auto",
            height: "100%",
            border: `5px ${
              window.getComputedStyle(document.querySelector("body"))
                .backgroundColor
            } solid`,
          }}
          floated="right"
        >
          <CartMain onAddLezione={handleAddLezione} />
        </Segment>
      </Segment>
    </TransitionablePortal>
  );
}

export default CartPortal;
