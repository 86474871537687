import * as types from "./actionTypes";

export function addPrivata(rigaPrivata) {
  return {
    type: types.CART_ADD_PRIVATA,
    riga: rigaPrivata,
  };
}

export function addCollettiva(rigaCollettiva) {
  return {
    type: types.CART_ADD_COLLETTIVA,
    riga: rigaCollettiva,
  };
}

export function addFast(rigaFast) {
  return {
    type: types.CART_ADD_FAST,
    riga: rigaFast,
  };
}

export function addBuono(rigaBuono) {
  return {
    type: types.CART_ADD_BUONO,
    riga: rigaBuono,
  };
}

export function submit() {
  return {
    type: types.CART_SUBMIT,
  };
}

export function removePrivata(index) {
  return {
    type: types.CART_REMOVE_PRIVATA,
    index,
  };
}

export function removeCollettiva(index) {
  return {
    type: types.CART_REMOVE_COLLETTIVA,
    index,
  };
}

export function removeFast(index) {
  return {
    type: types.CART_REMOVE_FAST,
    index,
  };
}

export function removeBuono(index) {
  return {
    type: types.CART_REMOVE_BUONO,
    index,
  };
}

export function setReferrer(referrer) {
  return {
    type: types.CART_SET_REFERRER,
    referrer,
  };
}

export function clearData() {
  return {
    type: types.APP_CLEAR,
  };
}

export function cartEmpty() {
  return {
    type: types.CART_EMPTY,
  };
}
