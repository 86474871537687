import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Segment, Header, Form } from "semantic-ui-react";
import GruppoSelezione from "../common/GruppoSelezione";
import FormError from "../common/FormError";
import { useTranslation } from "react-i18next";

function SchedaAllievoPrivate({ t, ...props }) {
  const [anniNascita] = useState(() => {
    const currentYear = moment().year();
    const minEta = 2;
    const maxEta = 99;

    const tempArray = new Array(97);

    for (
      let year = currentYear - minEta;
      year >= currentYear - maxEta;
      year--
    ) {
      tempArray[currentYear - minEta - year] = {
        key: year,
        text: year,
        value: year,
      };
    }

    return tempArray;
  });

  //const { i18n, t } = useTranslation();

  function handleLivelloChange(key) {
    props.onFieldChange(props.num, "livello", key);
  }

  function handleFieldChange(ev, data) {
    props.onFieldChange(props.num, data.name, data.value);
  }

  function handleSessoChange(key) {
    props.onFieldChange(props.num, "sesso", key);
  }

  return (
    <Segment>
      <Header as="h2" className="skiwork-titolo">
        {t("allievo")} {props.num}
      </Header>
      <Form>
        <Form.Input
          label={t("nome")}
          fluid
          name="nome"
          onChange={handleFieldChange}
          value={props.datiAllievo.nome}
          error={
            props.errors.nome
              ? {
                  content: t("nome_error"),
                  pointing: "below",
                }
              : null
          }
        ></Form.Input>
        <Form.Input
          label={t("cognome")}
          fluid
          name="cognome"
          onChange={handleFieldChange}
          value={props.datiAllievo.cognome}
          error={
            props.errors.cognome
              ? {
                  content: t("cognome_error"),
                  pointing: "below",
                }
              : null
          }
        ></Form.Input>
        <Form.Select
          label={t("anno_nascita")}
          fluid
          name="anno"
          onChange={handleFieldChange}
          options={anniNascita}
          value={props.datiAllievo.anno}
          error={
            props.errors.anno
              ? {
                  content: t("anno_error"),
                  pointing: "below",
                }
              : null
          }
        ></Form.Select>
        <GruppoSelezione
          titolo={t("sesso")}
          elementi={props.opzioniSesso}
          width={97}
          onChange={handleSessoChange}
          selezione={props.datiAllievo.sesso}
        ></GruppoSelezione>
        {props.errors.sesso && (
          <FormError
            errore="SELEZIONARE IL SESSO DELL'ALLIEVO"
            pointingDirection={"above"}
          ></FormError>
        )}
        <GruppoSelezione
          titolo={t("livello")}
          elementi={props.livelli}
          width={97}
          onChange={handleLivelloChange}
          selezione={props.datiAllievo.livello}
        ></GruppoSelezione>
        {props.errors.livello && (
          <FormError
            errore="SELEZIONARE IL LIVELLO DELL'ALLIEVO"
            pointingDirection={"above"}
          ></FormError>
        )}
      </Form>
    </Segment>
  );
}

SchedaAllievoPrivate.propTypes = {
  num: PropTypes.number.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  datiAllievo: PropTypes.object.isRequired,
  livelli: PropTypes.array.isRequired,
};

export default SchedaAllievoPrivate;
