import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  Segment,
  Header,
  Divider,
  List,
  Container,
  Icon,
  Button,
} from "semantic-ui-react";
import TestataPagina from "../common/TestataPagina";
import * as appActions from "../../redux/actions/appActions";
import * as cartActions from "../../redux/actions/cartActions";
import moment from "moment";
import useWindowSize from "../../utils/useWindowSize";
import PropTypes from "prop-types";
import useSkiworkUrlCheck from "../../utils/urlCheck";
import { useTranslation } from "react-i18next";
import ScalapayFrase from "../common/ScalapayFrase";

function CartMain({
  codScuola,
  lang,
  appData,
  datiScuola,
  cartData,
  setSlug,
  setLang,
  removePrivata,
  removeCollettiva,
  removeFast,
  removeBuono,
  ...props
}) {
  ////////////////////////////////////////
  //STATE
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [redirectToAbout, setRedirectToAbout] = useState(false);
  const windowSize = useWindowSize();
  const [noRighe, setNoRighe] = useState(() => {
    return (
      cartData.righeCollettive.length === 0 &&
      cartData.righePrivate.length === 0 &&
      cartData.righeFastBooking.length === 0 &&
      cartData.righeBuoni.length === 0
    );
  });
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  /////////////////////////////////////////
  //HOOKS
  const history = useHistory();

  const skiworkUrlCheck = useSkiworkUrlCheck(appData);

  //////////////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    if (appData.slug === undefined || appData.lang === undefined) {
      if (codScuola !== undefined && lang !== undefined) {
        setRedirectToHome(true);
      } else {
        setRedirectToAbout(true);
      }
    }
  }, []);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  ///////////////////////////////////////
  //HANDLERS
  function handleRimuoviRiga(tipo, idRiga) {
    //debugger;
    let offsetCollettive = 0;
    if (tipo === "c") {
      removeCollettiva({ index: idRiga });
      offsetCollettive = -1;
    }

    let offsetPrivate = 0;
    if (tipo === "p") {
      removePrivata({ index: idRiga });
      offsetPrivate = -1;
    }

    let offsetFast = 0;
    if (tipo === "f") {
      removeFast({ index: idRiga });
      offsetFast = -1;
    }

    let offsetBuoni = 0;
    if (tipo === "b") {
      removeBuono({ index: idRiga });
      offsetFast = -1;
    }

    setNoRighe(
      cartData.righeCollettive.length + offsetCollettive === 0 &&
        cartData.righePrivate.length + offsetPrivate === 0 &&
        cartData.righeFastBooking.length + offsetFast === 0 &&
        cartData.righeBuoni.length + offsetBuoni === 0
    );
  }

  return (
    <Container>
      <div className="head-offset"></div>
      {redirectToHome && <Redirect to={`/${lang}/${codScuola}/`} />}
      {redirectToAbout && <Redirect to={`/`} />}
      <TestataPagina
        titolo={t("carrello")}
        sottoTitolo={t("elenco_lezioni_acquistate")}
        icon="cart"
      />
      <Segment
        className="padding_5_lr contenitore-grande"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <Segment basic className="padding_0_lr" textAlign="right">
          {!noRighe && (
            <div
              style={{
                // marginRight: "3.5px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Totale
                cartData={cartData}
                datiScuola={datiScuola}
                t={t}
              ></Totale>
            </div>
          )}
          {noRighe && (
            <Segment basic textAlign="center">
              <Divider />
              <Header as="h2">{t("carrello_vuoto")}</Header>
              <Divider />
            </Segment>
          )}

          <Button
            icon
            primary
            size={windowSize.width > 600 ? "big" : "large"}
            style={{ marginBottom: "10px" }}
            fluid={windowSize.width < 600}
            onClick={() => {
              if (props.onAddLezione === undefined) {
                if (cartData.referrer === "private") {
                  history.push(`/${lang}/${codScuola}/private/programma`);
                } else {
                  if (cartData.referrer === "fast") {
                    history.push(`/${lang}/${codScuola}/fast`);
                  } else {
                    if (cartData.referrer === "collettive") {
                      history.push(`/${lang}/${codScuola}/collettive`);
                    } else {
                      history.push(`/${lang}/${codScuola}/`);
                    }
                  }
                }
              } else {
                props.onAddLezione();
              }
            }}
          >
            <Icon name="plus" />
            {"  "}
            {t("aggiungi_altre_lezioni")}
          </Button>

          <Button
            icon
            primary
            size={windowSize.width > 600 ? "big" : "large"}
            style={{ marginBottom: "10px" }}
            fluid={windowSize.width < 600}
            disabled={noRighe}
            onClick={() => {
              history.push(`/${lang}/${codScuola}/checkout`);
            }}
          >
            <Icon name="credit card" />
            {"  "}
            {t("checkout")}
          </Button>
        </Segment>
      </Segment>
      {!noRighe && (
        <Segment className="padding_5_lr contenitore-grande">
          <List>
            {cartData.righeFastBooking.map((r, idx) => {
              return (
                <List.Item key={idx}>
                  <List.Header as="h3" className="skiwork-testi">
                    {t("lezioni_private")} *FAST BOOKING*
                  </List.Header>
                  <div>{r.specialita}</div>
                  {moment(r.data).format("DD-MM-YYYY")} -{" "}
                  {r.dalle.substring(0, 5)} - {r.alle.substring(0, 5)}
                  {datiScuola.visualizzaMaestroFastBooking && (
                    <div>
                      {t("maestro")} {r.nomeMaestro} {t("ritrovo")} {r.descZona}{" "}
                      x {r.numPersone} {t("allievi")}
                    </div>
                  )}
                  {!datiScuola.visualizzaMaestroFastBooking && (
                    <div>
                      {t("ritrovo")} {r.descZona} x {r.numPersone}{" "}
                      {t("allievi")}
                    </div>
                  )}
                  <div style={{ textAlign: "right" }}>
                    <PrezzoRigaFast
                      riga={r}
                      id={idx}
                      onRemove={handleRimuoviRiga}
                      datiScuola={datiScuola}
                      t={t}
                    />
                  </div>
                  <Divider style={{ marginBottom: "0px" }} />
                </List.Item>
              );
            })}
            {cartData.righeBuoni.map((r, idx) => {
              return (
                <List.Item key={idx}>
                  <List.Header as="h3" className="skiwork-testi">
                    BUONO/VOUCHER
                  </List.Header>
                  <div style={{ fontWeight: "bold" }}>{r.titoloWeb}</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: r.descrizioneWeb,
                    }}
                  ></div>
                  <div style={{ textAlign: "right" }}>
                    <PrezzoRigaBuono
                      riga={r}
                      id={idx}
                      onRemove={handleRimuoviRiga}
                      datiScuola={datiScuola}
                      t={t}
                    />
                  </div>
                  <Divider style={{ marginBottom: "0px" }} />
                </List.Item>
              );
            })}
            {cartData.righeCollettive.map((r, idx) => {
              return (
                <List.Item key={idx}>
                  <List.Header as="h3" className="skiwork-testi">
                    {r.titolo}
                  </List.Header>
                  {r.numGiorniScelti} {t("giorni")} {t("dal")}{" "}
                  <span style={{ fontWeight: "700" }}>
                    {moment(r.startDate).format("DD-MM-YYYY")}
                  </span>{" "}
                  - <span style={{ fontWeight: "700" }}>{r.numAllievi}</span>{" "}
                  {t("allievi")}
                  <ServiziCorso riga={r} />
                  <div style={{ textAlign: "right" }}>
                    <PrezzoRigaCorso
                      riga={r}
                      id={idx}
                      onRemove={handleRimuoviRiga}
                      datiScuola={datiScuola}
                      t={t}
                    />
                  </div>
                  <Divider style={{ marginBottom: "0px" }} />
                </List.Item>
              );
            })}
            {cartData.righePrivate.map((r, idx) => {
              return (
                <List.Item>
                  <List.Header as="h3" className="skiwork-testi">
                    {t("lezioni_private")} {r.specialita}
                  </List.Header>
                  {moment(r.data).format("DD-MM-YYYY")} - {r.descFascia} -{" "}
                  {r.numAllievi} {t("allievi")} - {r.ore}h
                  <div style={{ textAlign: "right" }}>
                    <PrezzoRigaPrivata
                      riga={r}
                      id={idx}
                      onRemove={handleRimuoviRiga}
                      datiScuola={datiScuola}
                      t={t}
                    />
                  </div>
                  <Divider style={{ marginBottom: "0px" }} />
                </List.Item>
              );
            })}
          </List>
        </Segment>
      )}
    </Container>
  );
}

function Totale({ cartData, datiScuola, t }) {
  let totPagareCollettive = 0;
  let totCollettive = 0;
  let totCollettiveListino = 0;
  let totServiziCollettive = 0;
  let totPagareServiziCollettive = 0;
  let totPagarePrivate = 0;
  let totPrivate = 0;
  let totPrivateListino = 0;
  let totFastBooking = 0;
  let totBuoni = 0;

  //const { i18n, t } = useTranslation();

  //collettive
  for (let i = 0; i < cartData.righeCollettive.length; i++) {
    totPagareCollettive +=
      cartData.righeCollettive[i].prezzoPag *
      cartData.righeCollettive[i].numAllievi;
    totCollettive +=
      cartData.righeCollettive[i].prezzo *
      cartData.righeCollettive[i].numAllievi;
    totCollettiveListino +=
      cartData.righeCollettive[i].prezzoListino *
      cartData.righeCollettive[i].numAllievi;

    //Servizi
    for (let a = 0; a < cartData.righeCollettive[i].numAllievi; a++) {
      for (
        let s = 0;
        s < cartData.righeCollettive[i].serviziAllievo[a]?.length ?? 0;
        s++
      ) {
        if (
          cartData.righeCollettive[i].prezzo ===
          cartData.righeCollettive[i].prezzoPag
        ) {
          totPagareServiziCollettive +=
            cartData.righeCollettive[i].serviziAllievo[a][s].prezzo;
        }
        totServiziCollettive +=
          cartData.righeCollettive[i].serviziAllievo[a][s].prezzo;
      }
    }
  }

  //private
  for (let i = 0; i < cartData.righePrivate.length; i++) {
    totPagarePrivate += cartData.righePrivate[i].prezzoPag;
    totPrivate += cartData.righePrivate[i].prezzo;
    totPrivateListino += cartData.righePrivate[i].prezzoListino;
  }

  //fast
  for (let i = 0; i < cartData.righeFastBooking.length; i++) {
    totFastBooking +=
      cartData.righeFastBooking[i].prezzi[
        cartData.righeFastBooking[i].numPersone - 1
      ];
  }

  //buoni
  for (let i = 0; i < cartData.righeBuoni.length; i++) {
    totBuoni += cartData.righeBuoni[i].valore;
  }

  const totGeneraleListino =
    totCollettiveListino +
    totPrivateListino +
    totFastBooking +
    totBuoni +
    totServiziCollettive;
  const totGenerale =
    totCollettive +
    totPrivate +
    totFastBooking +
    totBuoni +
    totServiziCollettive;
  // const totPagare =
  //   totPagareCollettive +
  //   totPagarePrivate +
  //   totFastBooking +
  //   totBuoni +
  //   totPagareServiziCollettive;

  return (
    <Segment basic style={{ width: "280px" }}>
      {/* {totGeneraleListino > totGenerale && (
        <div className="riga-prezzo">
          <span className="prezzo_parziale">{t("prezzo_listino")}</span>
          <span className="prezzo_parziale prezzo-barrato">
            {datiScuola.valuta} {totGeneraleListino.toFixed(2)}
          </span>
        </div>
      )} */}
      <div className="riga-prezzo">
        {" "}
        <span className="prezzo_totale">{t("totale")}</span>
        <span className="prezzo_totale">
          {datiScuola.valuta} {totGenerale.toFixed(2)}
        </span>
      </div>
    </Segment>
  );
  //TUTTO a saldo
  // if (totGenerale === totPagare) {
  //   //Se ci sono servizi separo i servizi dalle lezioni
  //   if (totServiziCollettive > 0) {
  //     return (
  //       <div style={{ marginBottom: "14px" }}>
  //         <p style={{ fontSize: "18px", marginBottom: "0px" }}>
  //           {t("totale_lezioni")}{" "}
  //           {totGenerale < totGeneraleListino && (
  //             <span
  //               style={{ textDecoration: "line-through", marginRight: "7px" }}
  //             >
  //               {datiScuola.valuta} {totGeneraleListino.toFixed(2)}
  //             </span>
  //           )}
  //           <span>
  //             {datiScuola.valuta} {totGenerale.toFixed(2)}
  //           </span>
  //         </p>
  //         <p>
  //           {t("totale_servizi")} {datiScuola.valuta}{" "}
  //           {totServiziCollettive.toFixed(2)}
  //         </p>
  //         <Divider />
  //         <p className="prezzo_totale">
  //           {t("totale")} {datiScuola.valuta}{" "}
  //           {(totGenerale + totServiziCollettive).toFixed(2)}
  //         </p>
  //         {datiScuola.tipoBanca_2 > 0 && (
  //           <ScalapayFrase
  //             prezzo={((totGenerale + totServiziCollettive) / 3).toFixed(2)}
  //             end={true}
  //           />
  //         )}
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div style={{ marginBottom: "14px" }}>
  //         <p className="prezzo_totale">
  //           {t("totale")}
  //           {totGenerale < totGeneraleListino && (
  //             <span
  //               style={{
  //                 textDecoration: "line-through",
  //                 marginRight: "7px",
  //                 marginLeft: "7px",
  //               }}
  //             >
  //               {datiScuola.valuta} {totGeneraleListino.toFixed(2)}
  //             </span>
  //           )}
  //           <span>
  //             {datiScuola.valuta} {totGenerale.toFixed(2)}
  //           </span>
  //         </p>
  //         {datiScuola.tipoBanca_2 > 0 && (
  //           <ScalapayFrase prezzo={(totGenerale / 3).toFixed(2)} end={true} />
  //         )}
  //       </div>
  //     );
  //   }
  // } else {
  //   if (totServiziCollettive > 0) {
  //     return (
  //       <div style={{ marginBottom: "14px" }}>
  //         <p style={{ fontSize: "18px", marginBottom: "0px" }}>
  //           {t("totale_lezioni")} {datiScuola.valuta} {totGenerale.toFixed(2)}
  //         </p>
  //         <p>
  //           {t("totale_servizi")} {datiScuola.valuta}{" "}
  //           {totServiziCollettive.toFixed(2)}
  //         </p>
  //         <Divider />
  //         <p className="prezzo_parziale" style={{ marginBottom: "0px" }}>
  //           {t("totale")} {datiScuola.valuta}{" "}
  //           {(totGenerale + totServiziCollettive).toFixed(2)}
  //         </p>
  //         <p className="prezzo_totale">
  //           {t("acconto")} {datiScuola.valuta}{" "}
  //           {(totPagare + totPagareServiziCollettive).toFixed(2)}
  //         </p>
  //         {datiScuola.tipoBanca_2 > 0 && (
  //           <ScalapayFrase
  //             prezzo={((totPagare + totPagareServiziCollettive) / 3).toFixed(2)}
  //             end={true}
  //           />
  //         )}
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div style={{ marginBottom: "14px" }}>
  //         <p className="prezzo_parziale" style={{ marginBottom: "0px" }}>
  //           {t("totale")} {datiScuola.valuta} {totGenerale.toFixed(2)}
  //         </p>
  //         <p className="prezzo_totale">
  //           {t("acconto")} {datiScuola.valuta} {totPagare.toFixed(2)}
  //         </p>
  //         {datiScuola.tipoBanca_2 > 0 && (
  //           <ScalapayFrase prezzo={(totPagare / 3).toFixed(2)} end={true} />
  //         )}
  //       </div>
  //     );
  //   }
  // }
}

function ServiziCorso({ riga }) {
  let totServizi = 0;
  const servizi = [];
  for (let i = 0; i < riga.numAllievi; i++) {
    for (let s = 0; s < riga.serviziAllievo[i]?.length ?? 0; s++) {
      totServizi += parseFloat(riga.serviziAllievo[i][s].prezzo);
      servizi.push(riga.serviziAllievo[i][s]);
    }
  }
  if (totServizi > 0) {
    return servizi.map((s, idx) => {
      return <div key={idx}>+ {s.descrizione}</div>;
    });
  }
  return <></>;
}

function PrezzoRigaCorso({ riga, id, onRemove, datiScuola, t }) {
  const stileSegment = {
    marginBottom: "0px",
    paddingBottom: "0px",
    verticalAlign: "middle",
  };

  //verifico se ci sono servizi
  let totServizi = 0;
  for (let i = 0; i < riga.numAllievi; i++) {
    for (let s = 0; s < riga.serviziAllievo[i]?.length ?? 0; s++) {
      totServizi += parseFloat(riga.serviziAllievo[i][s].prezzo);
    }
  }

  //if (riga.prezzo === riga.prezzoPag) {
  return (
    <Segment basic textAlign="right" style={stileSegment}>
      <span
        style={{
          verticalAlign: "middle",
          fontSize: "18px",
          marginRight: "14px",
        }}
      >
        {riga.prezzo < riga.prezzoListino && (
          <span
            style={{
              textDecoration: "line-through",
              marginLeft: "7px",
              marginRight: "7px",
            }}
          >
            {datiScuola.valuta}{" "}
            {(riga.prezzoListino * riga.numAllievi + totServizi).toFixed(2)}
          </span>
        )}
        <span>
          {datiScuola.valuta}{" "}
          {(riga.prezzo * riga.numAllievi + totServizi).toFixed(2)}
        </span>
      </span>
      <span
        style={{ verticalAlign: "middle", cursor: "pointer" }}
        onClick={() => {
          onRemove("c", id);
        }}
      >
        {riga.prezzo !== riga.prezzoPag && (
          <span
            style={{
              verticalAlign: "middle",
              fontSize: "18px",
              marginRight: "14px",
            }}
          >
            ({t("acconto")} {datiScuola.valuta}{" "}
            {(riga.prezzoPag * riga.numAllievi).toFixed(2)})
          </span>
        )}
        <Icon name="x" size="large" />
      </span>
    </Segment>
  );
}

function PrezzoRigaPrivata({ riga, id, onRemove, datiScuola, t }) {
  const stileSegment = {
    marginBottom: "0px",
    paddingBottom: "0px",
    verticalAlign: "middle",
  };

  return (
    <Segment basic textAlign="right" style={stileSegment}>
      {riga.prezzo < riga.prezzoListino && (
        <span
          style={{
            textDecoration: "line-through",
            marginLeft: "7px",
            marginRight: "7px",
            fontSize: "18px",
            verticalAlign: "middle",
          }}
        >
          {datiScuola.valuta} {riga.prezzoListino.toFixed(2)}
        </span>
      )}
      <span
        style={{
          verticalAlign: "middle",
          fontSize: "18px",
          marginRight: "14px",
        }}
      >
        {datiScuola.valuta} {riga.prezzo.toFixed(2)}
      </span>
      <span
        style={{ verticalAlign: "middle", cursor: "pointer" }}
        onClick={() => {
          onRemove("p", id);
        }}
      >
        {riga.prezzoPag !== riga.prezzo && (
          <span
            style={{
              verticalAlign: "middle",
              fontSize: "18px",
              marginRight: "14px",
            }}
          >
            ({t("acconto")} {datiScuola.valuta} {riga.prezzoPag.toFixed(2)})
          </span>
        )}
        <Icon name="x" size="large" />
      </span>
    </Segment>
  );
}

function PrezzoRigaFast({ riga, id, onRemove, datiScuola }) {
  const stileSegment = {
    marginBottom: "0px",
    paddingBottom: "0px",
    verticalAlign: "middle",
  };

  return (
    <Segment basic textAlign="right" style={stileSegment}>
      {riga.prezzi[riga.numPersone - 1] <
        riga.prezziListino[riga.numPersone - 1] && (
        <span
          style={{
            textDecoration: "line-through",
            marginLeft: "7px",
            marginRight: "7px",
            fontSize: "18px",
            verticalAlign: "middle",
          }}
        >
          {datiScuola.valuta}{" "}
          {riga.prezziListino[riga.numPersone - 1].toFixed(2)}
        </span>
      )}
      <span
        style={{
          verticalAlign: "middle",
          fontSize: "18px",
          marginRight: "14px",
        }}
      >
        {datiScuola.valuta} {riga.prezzi[riga.numPersone - 1].toFixed(2)}
      </span>
      <span
        style={{ verticalAlign: "middle", cursor: "pointer" }}
        onClick={() => {
          onRemove("f", id);
        }}
      >
        <Icon name="x" size="large" />
      </span>
    </Segment>
  );
}

function PrezzoRigaBuono({ riga, id, onRemove, datiScuola }) {
  const stileSegment = {
    marginBottom: "0px",
    paddingBottom: "0px",
    verticalAlign: "middle",
  };

  return (
    <Segment basic textAlign="right" style={stileSegment}>
      <span
        style={{
          verticalAlign: "middle",
          fontSize: "18px",
          marginRight: "14px",
        }}
      >
        {datiScuola.valuta} {riga.valore.toFixed(2)}
      </span>
      <span
        style={{ verticalAlign: "middle", cursor: "pointer" }}
        onClick={() => {
          onRemove("b", id);
        }}
      >
        <Icon name="x" size="large" />
      </span>
    </Segment>
  );
}

CartMain.propTypes = {
  onAddLezione: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    codScuola:
      ownProps.match === undefined
        ? state.appReducer.slug
        : ownProps.match.params.slug,
    lang:
      ownProps.match === undefined
        ? state.appReducer.lang
        : ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    cartData: state.cartReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  setSlug: appActions.setSlug,
  setLang: appActions.setLang,
  removePrivata: cartActions.removePrivata,
  removeCollettiva: cartActions.removeCollettiva,
  removeFast: cartActions.removeFast,
  removeBuono: cartActions.removeBuono,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartMain);
