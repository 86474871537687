import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as cartActions from "../../redux/actions/cartActions";
import * as appActions from "../../redux/actions/appActions";
import * as corsiActions from "../../redux/actions/corsiActions";
import {
  Loader,
  Container,
  Segment,
  Grid,
  Divider,
  Icon,
  Flag,
  Header,
  Button,
  Popup,
} from "semantic-ui-react";
import { DayPickerSingleDateController } from "react-dates";
import TestataPagina from "../common/TestataPagina";
import GruppoSelezione from "../common/GruppoSelezione";
import SchedaAllievoPrivate from "../private/SchedaAllievoPrivate";
import * as signalRService from "../../utils/signalRService";
import moment from "moment";
import * as privateApi from "../../api/privateApi";
import * as calendarUtils from "../../utils/calendarUtils";
import * as skiworkUtils from "../../utils/skiworkUtils";
import useWindowSize from "../../utils/useWindowSize";
import { addFast } from "../../redux/actions/cartActions";
import CartPortal from "../common/CartPortal";
import { useTranslation } from "react-i18next";
import RitroviFastBooking from "./RitroviFastBooking";
import InfoWeb from "../common/InfoWeb";

function FastMain({
  codScuola,
  lang,
  appData,
  datiScuola,
  cartData,
  loading,
  addFast,
  setReferrer,
  setLang,
  setSlug,
  loadSchoolData,
  clearAppData,
  ...props
}) {
  ////////////////////////////////////////
  //STATE
  const [isLoading, setIsLoading] = useState(false);
  const [isDisponibilitaLoading, setIsDisponibilitaLoading] = useState(false);
  const [signalR, setSignalR] = useState(null);
  const [noServer, setNoServer] = useState(false);
  const [preferenze, setPreferenze] = useState({
    lingua: "",
    idSpecialita: "",
    specialita: "",
    numPersone: "1",
    datiAllievi: [
      { nome: "", cognome: "", livello: "1", anno: "", sesso: "M" },
      { nome: "", cognome: "", livello: "1", anno: "", sesso: "M" },
      { nome: "", cognome: "", livello: "1", anno: "", sesso: "M" },
      { nome: "", cognome: "", livello: "1", anno: "", sesso: "M" },
      { nome: "", cognome: "", livello: "1", anno: "", sesso: "M" },
    ],
  });
  const [errorsAllievi, setErrorsAllievi] = useState(() => {
    const errorsArray = [];
    for (let i = 0; i < 5; i++) {
      errorsArray.push({});
    }
    return errorsArray;
  });

  const [oraSelezionata, setOraSelezionata] = useState(-1);
  const [listaLingue, setListaLingue] = useState([]);
  const [listaSpecialita, setListaSpecialita] = useState([]);
  const [data, setData] = useState(null);
  const [fastBookingInfo, setFastBookingInfo] = useState(undefined);
  const [disponibilitaFast, setDisponibilitaFast] = useState(undefined);
  const [dettagliSelezione, setDettagliSelezione] = useState({});
  const [preferenzaRitrovo, setPreferenzaRitrovo] = useState("-1");
  const [checkOutOpen, setCheckOutOpen] = useState(false);
  const [noDisponibilita, setNoDisponibilita] = useState(false);
  const windowSize = useWindowSize();
  const history = useHistory();
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  const location = useLocation();

  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  ////////////////////////////////////////////
  //REFS
  const timeoutHandle = useRef(null);

  ////////////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    if (codScuola !== appData.slug || lang !== appData.lang) {
      clearAppData();
      loadSchoolData(codScuola, lang);
      setSlug(codScuola);
      setLang(lang);
    }

    setIsLoading(true);
    const getSignalRConnection = async () => {
      const conn = await signalRService.setUpSignalRConnection(handlePong);
      setSignalR(conn);
    };
    getSignalRConnection();

    let spec = [
      {
        text: "NO PREF",
        img: require(`../../assets/images/defaults/free.jpg`),
        key: "",
      },
    ];
    spec = spec.concat(
      datiScuola.specialita.map((s) => {
        return {
          key: s.idspecialita.toString(),
          img: require(`../../assets/images/specialita/${
            s.simbolo === null ? "0" : s.simbolo
          }.jpg`),
          //text: s.descrSpecialita,
          text: skiworkUtils.getDescrizioneSpecialita(
            s,
            datiScuola.idLinguaInUso
          ),
        };
      })
    );
    setListaSpecialita(spec);

    const lingue = [
      {
        text: "NO PREF",
        img: require(`../../assets/images/defaults/free.jpg`),
        key: "",
      },
    ];
    for (let key in datiScuola.lingueMaestro) {
      const isoCode = datiScuola.lingueMaestro[key].toUpperCase();

      let imgSrc;
      try {
        //imgSrc = require(`../../assets/images/flags/${isoCode}.svg`);
        imgSrc = require(`../../assets/images/flags/${key}.jpg`);
      } catch {
        imgSrc = require(`../../assets/images/flags/1.jpg`);
      }

      lingue.push({
        text: isoCode,
        img: imgSrc,
        key: isoCode.toUpperCase(),
      });
    }
    setListaLingue(lingue);
  }, []);

  useEffect(() => {
    if (signalR === null) return;

    privateApi.PingFastBooking(
      datiScuola.id,
      datiScuola.codiceScuola,
      signalR.connectionId
    );

    timeoutHandle.current = setTimeout(() => {
      setNoServer(true);
      setIsLoading(false);
    }, 10000);

    return () => clearTimeout(timeoutHandle.current);
  }, [signalR]);

  useEffect(() => {
    if (disponibilitaFast !== undefined) {
      if (disponibilitaFast.length === 1)
        handleSelezioneChange(0, disponibilitaFast[0]);
      if (disponibilitaFast.length === 0) setOraSelezionata(-1);
      setIsDisponibilitaLoading(false);
    }
  }, [disponibilitaFast]);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  useEffect(() => {
    async function carica() {
      await CaricaDisponibilita(data);
    }

    carica();
  }, [preferenze.lingua]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search.toLowerCase());
    if (searchParams.has("location")) {
      if (disponibilitaFast !== undefined) {
        const list = disponibilitaFast
          .map((d) => {
            return d.descZona;
          })
          .filter((v, i, a) => a.indexOf(v) === i);

        const res = list.find(
          (x) =>
            x.replace(/\s+/g, "").toLowerCase() ===
            searchParams.get("location").toLocaleLowerCase()
        );

        if (res !== undefined) {
          setPreferenzaRitrovo(res);
        }
      }
    }
  }, [location, disponibilitaFast]);
  //////////////////////////////////////////
  //HANDLERS
  async function handlePong() {
    clearTimeout(timeoutHandle.current);
    timeoutHandle.current = null;
    //carico le info generali (limiti di date, date buche etc...)
    const res = await privateApi.getFastBookingInfo(
      datiScuola.id,
      datiScuola.codiceScuola
    );
    setNoDisponibilita(res.noDisponibilita);
    setFastBookingInfo(res);
    //setData(moment(res.dataMin));
    setIsLoading(false);
  }

  async function handleDateChange(val) {
    setData(val);

    if (preferenze.idSpecialita.length > 0) {
      await CaricaDisponibilita(val);
      setOraSelezionata(-1);
      setDettagliSelezione({});
    }
  }

  async function handleSpecialitaChange(key) {
    if (key.length === 0) return;

    setPreferenze({ ...preferenze, idSpecialita: key });
    //if (disponibilitaFast === undefined && key.length > 0) {
    await CaricaDisponibilita(data);
    //}
  }

  async function handleLinguaChange(id) {
    setPreferenze({ ...preferenze, lingua: id });
    setOraSelezionata(-1);
  }

  function handleNumPersoneChange(key) {
    setPreferenze({ ...preferenze, numPersone: key });
  }

  function handleSelezioneChange(idx, dati) {
    setOraSelezionata(idx);
    console.log(dati);
    setDettagliSelezione(dati);
  }

  function handleAllievoFieldChange(idx, field, value) {
    const datiAllieviTmp = [...preferenze.datiAllievi];
    datiAllieviTmp[idx - 1][field] = value;
    setPreferenze({ ...preferenze, datiAllievi: datiAllieviTmp });
    //setDatiAllievi(datiAllieviTmp);

    const errorsTmp = [...errorsAllievi];
    errorsTmp[idx - 1][field] = false;
    setErrorsAllievi(errorsTmp);
  }

  function handleAvanti() {
    if (checkErrors()) return;

    //correggo i livelli
    for (let i = 0; i < preferenze.datiAllievi.length; i++) {
      if (preferenze.datiAllievi[i].cognome !== "") {
        preferenze.datiAllievi[i].livello = datiScuola.tLivelli.filter(
          (x) => x.id === parseInt(preferenze.datiAllievi[i].livello)
        )[0].idLivelloIso;
      }
    }

    // console.log(listaSpecialita);
    // console.log(preferenze.idSpecialita);
    // console.log(
    //   listaSpecialita.find(
    //     (x) => parseInt(x.key) === parseInt(preferenze.idSpecialita)
    //   )
    // );

    addFast({
      ...dettagliSelezione,
      specialita:
        listaSpecialita.find(
          (x) => parseInt(x.key) === parseInt(preferenze.idSpecialita)
        )?.text ?? "",
      numPersone: parseInt(preferenze.numPersone),
      lingua: preferenze.lingua,
      allievi: preferenze.datiAllievi,
    });

    setOraSelezionata(-1);
    setDettagliSelezione({});

    setReferrer("fast");
    if (windowSize.width > 600) {
      setCheckOutOpen(true);
    } else {
      history.push(`/${appData.lang}/${appData.slug}/cart`);
    }
  }

  function handleCartPortalClose() {
    setPreferenze({
      ...preferenze,
      lingua: "",
      idSpecialita: "",
      specialita: "",
      numPersone: "1",
    });
    setDisponibilitaFast(undefined);
    setCheckOutOpen(false);
    history.push(`/${appData.lang}/${appData.slug}/fast`);
  }

  function handleRitrovoChange(value, par2, par3) {
    setPreferenzaRitrovo(value);
  }

  //////////////////////////////////////////////////
  //HELPERS
  function checkErrors() {
    const errorsTmp = [...errorsAllievi];
    let error = false;
    for (let i = 0; i < preferenze.numPersone; i++) {
      if (preferenze.datiAllievi[i].nome.length < 2) {
        errorsTmp[i].nome = true;
        error = true;
      }
      if (preferenze.datiAllievi[i].cognome.length < 2) {
        errorsTmp[i].cognome = true;
        error = true;
      }
      if (preferenze.datiAllievi[i].sesso.length < 1) {
        errorsTmp[i].sesso = true;
        error = true;
      }
      if (preferenze.datiAllievi[i].anno < 1900) {
        errorsTmp[i].anno = true;
        error = true;
      }
      if (
        parseInt(preferenze.datiAllievi[i].livello) === 0 ||
        preferenze.datiAllievi[i].livello === ""
      ) {
        errorsTmp[i].livello = true;
        error = true;
      }
    }

    for (let i = preferenze.numPersone; i < 5; i++) {
      errorsTmp[i] = {};
    }

    if (error) {
      setErrorsAllievi(errorsTmp);
    } else {
      setErrorsAllievi([{}, {}, {}, {}, {}]);
    }

    return error;
  }

  async function CaricaDisponibilita(dataRif) {
    console.log("carica disponibilità");
    console.log(preferenze);
    setIsDisponibilitaLoading(true);
    var res = await privateApi.getDisponibilitaFast(
      datiScuola.id,
      dataRif === null ? "1900-01-01" : dataRif.format("YYYY-MM-DD"),
      preferenze.lingua,
      -1,
      appData.lang,
      datiScuola.codiceScuola
    );

    //NEW
    //Per ogni riga di disponibilita verifico in modo diverso se in base a quanto c'è nel carrello è vendibile
    for (let i = res.oreDisponibili.length - 1; i >= 0; i--) {
      if (res.oreDisponibili[i].conMaestro) {
        for (let j = 0; j < cartData.righeFastBooking.length; j++) {
          if (i > res.oreDisponibili.length - 1 > 0) break;
          if (
            res.oreDisponibili[i].data === cartData.righeFastBooking[j].data &&
            res.oreDisponibili[i].dalle ===
              cartData.righeFastBooking[j].dalle &&
            res.oreDisponibili[i].alle === cartData.righeFastBooking[j].alle &&
            res.oreDisponibili[i].idMaestro ===
              cartData.righeFastBooking[j].idMaestro
          ) {
            res.oreDisponibili.splice(i, 1);
          }
        }
      } else {
        let count = 0;
        for (let j = 0; j < cartData.righeFastBooking.length; j++) {
          if (i > res.oreDisponibili.length - 1 > 0) break;
          if (
            cartData.righeFastBooking[j].data === res.oreDisponibili[i].data &&
            cartData.righeFastBooking[j].dalle ===
              res.oreDisponibili[i].dalle &&
            cartData.righeFastBooking[j].alle === res.oreDisponibili[i].alle &&
            cartData.righeFastBooking[j].idZona ===
              res.oreDisponibili[i].idZona &&
            cartData.righeFastBooking[j].idSpecialita ===
              res.oreDisponibili[i].idSpecialita &&
            cartData.righeFastBooking[i].conMaestro === false
          ) {
            count++;
          }
        }
        if (count === res.oreDisponibili[i].numDisponibili) {
          res.oreDisponibili.splice(i, 1);
        }
      }
    }

    //filtro in base alla lingua
    if (preferenze.lingua !== null && preferenze?.lingua.length > 0) {
      res.oreDisponibili = res.oreDisponibili.filter((o) =>
        o.lingue.includes(preferenze.lingua.toUpperCase())
      );
    }

    setDisponibilitaFast(res.oreDisponibili);
    if (res.oreDisponibili.length < 1) setOraSelezionata(-1);
    setIsDisponibilitaLoading(false);
  }

  if (
    loading ||
    appData.slug === undefined ||
    isLoading ||
    datiScuola === null ||
    datiScuola === undefined ||
    Object.keys(datiScuola).length === 0
  ) {
    return <Loader active></Loader>;
  } else {
    return (
      <Container>
        <div className="head-offset"></div>
        <TestataPagina
          titolo={t("lezioni_fast")}
          icon="user"
          sottoTitolo={t("scegli_lezione")}
        />
        {noDisponibilita && (
          <Segment textAlign="center">
            <Divider />
            <h2>{t("servizio_non_disponibile")}</h2>
            <Divider />
          </Segment>
        )}
        {noServer && (
          <Segment textAlign="center">
            <Divider />
            <h2>{t("servizio_non_disponibile")}</h2>
            <Divider />
          </Segment>
        )}
        {(isLoading || loading || fastBookingInfo === undefined) &&
          !noServer &&
          !noDisponibilita && <Loader active size="huge" />}
        {!isLoading &&
          !loading &&
          fastBookingInfo !== undefined &&
          !noServer &&
          !noDisponibilita && (
            <Segment className="padding_0_lr">
              <Grid stackable>
                <Grid.Column width={7} textAlign="center">
                  <Left
                    data={data}
                    fastBookingInfo={fastBookingInfo}
                    onDateChange={handleDateChange}
                    datiScuola={datiScuola}
                    t={t}
                  />
                </Grid.Column>
                <Grid.Column width={9}>
                  <Right
                    data={data}
                    preferenze={preferenze}
                    listaSpecialita={listaSpecialita}
                    listaLingue={listaLingue}
                    fastBookingInfo={fastBookingInfo}
                    disponibilitaFast={disponibilitaFast}
                    oraSelezionata={oraSelezionata}
                    dettagliSelezione={dettagliSelezione}
                    errorsAllievi={errorsAllievi}
                    noDisponibilita={noDisponibilita}
                    onSpecialitaChange={handleSpecialitaChange}
                    onLinguaChange={handleLinguaChange}
                    onNumPersoneChange={handleNumPersoneChange}
                    onSelezioneChange={handleSelezioneChange}
                    onAllievoFieldChange={handleAllievoFieldChange}
                    onAvanti={handleAvanti}
                    preferenzaRitrovo={preferenzaRitrovo}
                    onRitrovoChange={handleRitrovoChange}
                    loading={isDisponibilitaLoading}
                    datiScuola={datiScuola}
                    t={t}
                  />
                </Grid.Column>
              </Grid>
            </Segment>
          )}
        <CartPortal open={checkOutOpen} onClose={handleCartPortalClose} />
      </Container>
    );
  }
}

function Left({ data, onDateChange, fastBookingInfo, t, datiScuola }) {
  const [focus, setFocus] = useState(null);
  const windowSize = useWindowSize();
  //const { i18n, t } = useTranslation();

  return (
    <>
      <Segment inverted className="skiwork-header" textAlign="center">
        {t("data_lezione")}
      </Segment>
      <Segment basic className="padding_0_lr">
        <DayPickerSingleDateController
          date={data}
          onDateChange={onDateChange}
          focused={focus}
          onFocusChange={(ev) => {
            setFocus(ev.focused);
          }}
          isOutsideRange={(day) =>
            !calendarUtils.isInclusivelyAfterDay(
              day,
              moment(fastBookingInfo.dataMin)
            ) || calendarUtils.isAfterDay(day, moment(fastBookingInfo.dataMax))
          }
          isDayBlocked={(day1) =>
            fastBookingInfo.dateVuote.some((day2) =>
              calendarUtils.isSameDay(day1, moment(day2))
            )
          }
          noBorder
          hideKeyboardShortcutsPanel
          orientation={windowSize.width > 600 ? "horizontal" : "vertical"}
        />
      </Segment>

      <div
        style={{
          //marginLeft: windowSize.width > 600 ? "2em" : "2px",
          marginTop: "2em",
        }}
      >
        <InfoWeb
          testi={datiScuola.infoWeb.filter((x) => x.sezione === 1)}
          sezione={1}
        />
      </div>
    </>
  );
}

function Right({
  data,
  preferenze,
  listaSpecialita,
  listaLingue,
  fastBookingInfo,
  disponibilitaFast,
  noDisponibilita,
  dettagliSelezione,
  datiScuola,
  oraSelezionata,
  errorsAllievi,
  onSpecialitaChange,
  onLinguaChange,
  onSelezioneChange,
  onNumPersoneChange,
  onAllievoFieldChange,
  onAvanti,
  loading,
  preferenzaRitrovo,
  onRitrovoChange,
  t,
}) {
  const listaNumPersone = Array.from({ length: 5 }, (_, i) => {
    return {
      key: (i + 1).toString(),
      text: (i + 1).toString(),
      img: require(`../../assets/images/people/${i + 1}.jpg`),
    };
  });

  //const { i18n, t } = useTranslation();
  const [opzioniSesso] = useState([
    {
      key: "M",
      text: "M",
      img: require(`../../assets/images/people/Male2.jpg`),
    },
    {
      key: "F",
      text: "F",
      img: require(`../../assets/images/people/Female2.jpg`),
    },
  ]);

  const [livelli, setLivelli] = useState(skiworkUtils.getLivelli(datiScuola));
  const [disp, setDisp] = useState([]);
  const [listaRitrovi, setListaRitrovi] = useState([]);

  useEffect(() => {
    if (disponibilitaFast === undefined) return;
    let dispTmp = disponibilitaFast.filter((x) => {
      return x.idSpecialita + 1 === parseInt(preferenze.idSpecialita);
    });

    if ((fastBookingInfo.conMaestro ? "" : preferenze.lingua).length > 0) {
      dispTmp = dispTmp.filter((x) => {
        return x.lingue.some(
          (y) => y === (fastBookingInfo.conMaestro ? "" : preferenze.lingua)
        );
      });
    }

    if (preferenzaRitrovo !== "-1") {
      dispTmp = dispTmp.filter((x) => x.descZona === preferenzaRitrovo);
    }

    setListaRitrovi(
      disponibilitaFast
        .map((d) => {
          return d.descZona;
        })
        .filter((v, i, a) => a.indexOf(v) === i)
    );

    console.log(dispTmp);

    setDisp(dispTmp);
  }, [
    disponibilitaFast,
    preferenze.idSpecialita,
    preferenze.lingua,
    preferenzaRitrovo,
  ]);

  useEffect(() => {
    if (
      !listaRitrovi.includes(preferenzaRitrovo) &&
      preferenzaRitrovo !== "-1"
    ) {
      onRitrovoChange("-1");
    }
  }, [data, listaRitrovi]);
  // let disp = props.disponibilita.filter((x) => {
  //   return x.idSpecialita + 1 === props.idSpecialita;
  // });

  // if (props.lingua.length > 0) {
  //   disp = disp.filter((x) => {
  //     return x.lingue.some((y) => y === props.lingua);
  //   });
  // }

  const onOraSelezioneChange = (par1, datiLezione) => {
    onSelezioneChange(par1, datiLezione);
    setLivelli(skiworkUtils.getLivelliZona(datiScuola, datiLezione.idZona));
  };

  const windowSize = useWindowSize();

  function renderAllievi() {
    const allieviElements = [];

    for (let i = 0; i < preferenze.numPersone; i++) {
      allieviElements.push(
        <SchedaAllievoPrivate
          num={i + 1}
          livelli={livelli}
          errors={errorsAllievi[i]}
          onFieldChange={onAllievoFieldChange}
          datiAllievo={preferenze.datiAllievi[i]}
          key={i}
          opzioniSesso={opzioniSesso}
          t={t}
        />
      );
    }

    return allieviElements;
  }

  if (loading) {
    return <Loader active size="huge" />;
  }
  return (
    <>
      <Segment inverted className="skiwork-header" textAlign="center">
        {t("lezioni_disponibili")}
      </Segment>
      <GruppoSelezione
        width={97}
        elementi={listaSpecialita}
        onChange={onSpecialitaChange}
        selezione={preferenze.idSpecialita}
        titolo={t("specialita_lezione")}
      ></GruppoSelezione>
      <Divider />
      {preferenze.idSpecialita === "" ? (
        <Segment textAlign="center" basic>
          <p>
            <Icon name="arrow up" size="huge"></Icon>
          </p>
          <p>{t("scegli_specialita")}</p>
        </Segment>
      ) : (
        <>
          {/* {!fastBookingInfo.conMaestro && ( */}
          <>
            <GruppoSelezione
              width={97}
              elementi={listaLingue}
              onChange={onLinguaChange}
              selezione={preferenze.lingua}
              titolo={t("scegli_lingua")}
            ></GruppoSelezione>
            <Divider />
          </>
          {/* )} */}
          {/* <div className="dummy_place_holder" ref={elencoOreRef} /> */}
          <ElencoOre
            disp={disp}
            idSpecialita={parseInt(preferenze.idSpecialita)}
            lingua={fastBookingInfo.conMaestro ? "" : preferenze.lingua}
            conMaestri={fastBookingInfo.conMaestro}
            oraSelezionata={oraSelezionata}
            onChange={onOraSelezioneChange}
            preferenzaRitrovo={preferenzaRitrovo}
            handlePreferenzaRitrovoChange={onRitrovoChange}
            listaRitrovi={listaRitrovi}
            t={t}
          ></ElencoOre>
          <Divider />
          {oraSelezionata >= 0 &&
            disponibilitaFast.length > 0 &&
            disp.length > 0 && (
              <>
                <GruppoSelezione
                  width={97}
                  elementi={listaNumPersone}
                  onChange={onNumPersoneChange}
                  selezione={preferenze.numPersone}
                  titolo={t("numero_allievi")}
                ></GruppoSelezione>
                <Divider />
                <label>{t("allievi")}</label>
                {renderAllievi()}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ width: "300px" }}>
                    {dettagliSelezione.prezziListino[
                      parseInt(preferenze.numPersone) - 1
                    ] >
                      dettagliSelezione.prezzi[
                        parseInt(preferenze.numPersone) - 1
                      ] && (
                      <div className="riga-prezzo">
                        <span className="prezzo_parziale">
                          {t("prezzo_listino")}
                        </span>{" "}
                        <span className="prezzo_parziale prezzo-barrato">
                          {datiScuola.valuta}{" "}
                          {dettagliSelezione.prezziListino[
                            parseInt(preferenze.numPersone) - 1
                          ].toFixed(2)}
                        </span>
                      </div>
                    )}
                    <div className="riga-prezzo">
                      <span className="prezzo_parziale">
                        {t("prezzo_online")}
                      </span>{" "}
                      <span className="prezzo_parziale">
                        {datiScuola.valuta}{" "}
                        {dettagliSelezione.prezzi[
                          parseInt(preferenze.numPersone) - 1
                        ].toFixed(2)}
                      </span>
                    </div>
                    <Divider />
                    <div className="riga-prezzo">
                      <span className="prezzo_totale">{t("Totale")}</span>{" "}
                      <span className="prezzo_totale">
                        {datiScuola.valuta}{" "}
                        {dettagliSelezione.prezzi[
                          parseInt(preferenze.numPersone) - 1
                        ].toFixed(2)}
                      </span>
                    </div>
                    <Divider />
                  </div>
                  {/* {datiScuola.tipoBanca_2 > 0 && (
          <ScalapayFrase prezzo={(prezzo / 3).toFixed(2)} end={true} />
        )} */}
                  <Divider />
                </div>
                {/* <Segment basic textAlign="right">
                  <p className="prezzo_totale">
                    {" "}
                    {t("prezzo")} {datiScuola.valuta}{" "}
                    {dettagliSelezione.prezzi[
                      parseInt(preferenze.numPersone) - 1
                    ].toFixed(2)}
                  </p>
                </Segment> */}
              </>
            )}
          {/* <div className="dummy_place_holder" ref={buttonAvantiRef} /> */}
          <div
            style={{
              textAlign: windowSize.width > 600 ? "right" : "center",
            }}
          >
            {oraSelezionata >= 0 && (
              <Button primary onClick={onAvanti} size="big">
                <Icon name="cart plus"></Icon>
                {t("aggiungi_lezione")}
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
}

function ElencoOre({ t, ...props }) {
  if (props.disp !== undefined) {
    return (
      <Segment basic style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        {props.oraSelezionata < 0 && props.disp.length > 0 && (
          <Segment basic textAlign="center" style={{ padding: "0" }}>
            {" "}
            <p>{t("scegli_lezione")}</p>
            <p>
              <Icon name="arrow down" size="huge"></Icon>
            </p>
          </Segment>
        )}
        <Segment basic style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <label>{t("ritrovo")}</label>
          <RitroviFastBooking
            disp={props.disp}
            selezione={props.preferenzaRitrovo}
            onChange={props.handlePreferenzaRitrovoChange}
            listaRitrovi={props.listaRitrovi}
            textNP={t("NESSUNA_PREFERENZA")}
          />
        </Segment>
        <label>{t("fasce_orarie_disponibili")}</label>
        {props.disp.length > 0 ? (
          <Grid
            style={{ marginTop: "5px", marginLeft: "0px", paddingLeft: "0px" }}
          >
            {props.disp.map((el, idx) => (
              <Grid.Column
                style={{
                  width: "185px",
                  paddingRight: "10px",
                  paddingLeft: "0px",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                }}
                key={idx}
              >
                {/* {props.conMaestri ? ( */}
                {el.conMaestro ? (
                  <ElementoConMaestro
                    element={el}
                    index={idx}
                    selectedKey={props.oraSelezionata}
                    onChange={() => {
                      props.onChange(idx, el);
                    }}
                    t={t}
                  />
                ) : (
                  <ElementoSenzaMaestro
                    element={el}
                    index={idx}
                    selectedKey={props.oraSelezionata}
                    onChange={() => {
                      props.onChange(idx, el);
                    }}
                    t={t}
                  />
                )}
              </Grid.Column>
            ))}
          </Grid>
        ) : (
          <Segment>{t("nessuna_disponibilita")}</Segment>
        )}
      </Segment>
    );
  } else {
    return <></>;
  }
}

function ElementoSenzaMaestro({ t, ...props }) {
  //const { i18n, t } = useTranslation();
  // console.log(props.element);
  return (
    <Segment
      className={`select_element ${
        props.index === props.selectedKey ? "active" : ""
      } `}
      onClick={() => {
        props.onChange(props.index);
      }}
      textAlign="center"
      style={{
        paddingLeft: "2px",
        paddingRight: "2px",
        marginTop: "10px",
        backgroundColor: "white",
      }}
    >
      <Header as="h2" textAlign="center" style={{ marginBottom: "0px" }}>
        {`${props.element.dalle.substring(
          0,
          5
        )} - ${props.element.alle.substring(0, 5)}`}
      </Header>
      <Divider />
      <p
        style={{
          fontSize: "12px",
          marginBottom: "0px",
          color: "rgba(48, 48, 48, 0.87)",
        }}
      >
        {t("ritrovo")}
      </p>
      <p style={{ fontWeight: "700", color: "rgba(48, 48, 48, 0.87)" }}>
        {props.element.descZona}
      </p>
      {props.element.titolo.length === 0 && (
        <p style={{ marginBottom: "0px" }}>&nbsp;</p>
      )}

      {props.element.titolo.length < 12 && <p>{props.element.titolo}</p>}
      {props.element.titolo.length >= 12 && (
        <div>
          <Popup
            trigger={<Button circular color="facebook" icon="info"></Button>}
          >
            <Popup.Header>Lesson Info</Popup.Header>
            <Popup.Content>{props.element.titolo}</Popup.Content>
          </Popup>
        </div>
      )}
    </Segment>
  );
}

function ElementoConMaestro({ t, ...props }) {
  //const { i18n, t } = useTranslation();
  return (
    <Segment
      className={`select_element ${
        props.index === props.selectedKey ? "active" : ""
      } `}
      onClick={() => {
        props.onChange(props.index);
      }}
      textAlign="center"
      style={{
        paddingLeft: "2px",
        paddingRight: "2px",
        marginTop: "10px",
        backgroundColor: "white",
      }}
    >
      <Header as="h2" textAlign="center" style={{ marginBottom: "0px" }}>
        {`${props.element.dalle.substring(
          0,
          5
        )} - ${props.element.alle.substring(0, 5)}`}
      </Header>
      <Divider />
      <p
        style={{
          fontSize: "12px",
          marginBottom: "0px",
          color: "rgba(48, 48, 48, 0.87)",
        }}
      >
        {t("maestro")}
      </p>
      <p style={{ fontWeight: "700", color: "rgba(48, 48, 48, 0.87)" }}>
        {props.element.nomeMaestro}
      </p>
      <p
        style={{
          fontSize: "12px",
          color: "rgba(48, 48, 48, 0.87)",
          marginBottom: "0px",
        }}
      >
        {t("ritrovo")}
      </p>
      <p style={{ fontWeight: "700", color: "rgba(48, 48, 48, 0.87)" }}>
        {props.element.descZona}
      </p>
      <p
        style={{
          fontSize: "12px",
          color: "rgba(48, 48, 48, 0.87)",
          marginBottom: "0px",
        }}
      >
        {t("lingue")}
      </p>
      <Segment
        basic
        style={{
          marginTop: "0px",
          paddingTop: "0px",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        {props.element.lingue.map((x) => (
          <Flag name={x.toLowerCase()} key={x.toLowerCase()} />
        ))}
      </Segment>
    </Segment>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    cartData: state.cartReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  setLang: appActions.setLang,
  setSlug: appActions.setSlug,
  setCorsoDettagli: appActions.setCorsoDettagli,
  addFast: cartActions.addFast,
  setReferrer: cartActions.setReferrer,
  loadSchoolData: scuolaActions.loadSchoolData,
  loadSessioni: corsiActions.loadSessioni,
  clearAppData: appActions.clearAppData,
};

export default connect(mapStateToProps, mapDispatchToProps)(FastMain);
