import React from "react";
import PropTypes from "prop-types";
import useWindowSize from "../../utils/useWindowSize";
import { Form, Grid, Dropdown, Select } from "semantic-ui-react";

const FormClienteNew = ({
  handleFieldChange,
  datiCliente,
  listaNazioni,
  listaNazioniDial,
  listaLingue,
  listaHotel,
  isCheckout,
  richiestaHotel,
  errors,
  t,
  ...props
}) => {
  const windowSize = useWindowSize();

  return (
    <Form>
      {/* <Form.Group
        inline
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Form.Radio
          label={t("AZIENDA")}
          value="A"
          name="tipo"
          checked={datiCliente.tipo === "A"}
          onChange={handleFieldChange}
        ></Form.Radio>
        <Form.Radio
          label={t("PRIVATO")}
          value="P"
          name="tipo"
          checked={datiCliente.tipo === "P"}
          onChange={handleFieldChange}
        ></Form.Radio>
      </Form.Group> */}

      <Form.Input
        fluid
        label={datiCliente.tipo === "P" ? t("nome") : t("Ragione_Sociale")}
        placeholder={
          datiCliente.tipo === "P" ? t("nome") : t("Ragione_Sociale")
        }
        name="nome"
        onChange={handleFieldChange}
        value={datiCliente.nome}
        error={
          errors.nome
            ? {
                content: t("nome_error"),
                pointing: "below",
              }
            : null
        }
      ></Form.Input>
      {datiCliente.tipo === "P" && (
        <Form.Input
          fluid
          label={t("cognome")}
          placeholder={t("cognome")}
          name="cognome"
          onChange={handleFieldChange}
          value={datiCliente.cognome}
          error={
            errors.cognome
              ? {
                  content: t("cognome_error"),
                  pointing: "below",
                }
              : null
          }
        ></Form.Input>
      )}
      <Form.Dropdown
        fluid
        label={t("nazione")}
        selection
        options={listaNazioni}
        placeholder={t("nazione")}
        name="nazione"
        onChange={handleFieldChange}
        value={datiCliente.nazione}
        search
        error={
          errors.nazione
            ? {
                content: t("nazione_error"),
                pointing: "below",
              }
            : null
        }
      />
      <Form.Group>
        <Form.Input
          fluid
          label={t("citta")}
          width={datiCliente.nazione === "IT" ? 10 : 13}
          placeholder={t("citta")}
          name="citta"
          onChange={handleFieldChange}
          value={datiCliente.citta}
          error={
            errors.citta
              ? {
                  content: t("citta_error"),
                  pointing: "below",
                }
              : null
          }
        ></Form.Input>
        {datiCliente.nazione === "IT" && (
          <Form.Dropdown
            width={3}
            fluid
            label={t("Provincia")}
            selection
            options={props.listaProvince}
            placeholder={t("Provincia")}
            name="provincia"
            onChange={handleFieldChange}
            value={datiCliente.provincia}
            search
            error={
              errors.provincia
                ? {
                    content: t("provincia_error"),
                    pointing: "below",
                  }
                : null
            }
          />
        )}
        <Form.Input
          fluid
          label={t("cap")}
          width={datiCliente.nazione === "IT" ? 3 : 6}
          placeholder={t("cap")}
          name="cap"
          onChange={handleFieldChange}
          value={datiCliente.cap}
          error={
            errors.cap
              ? {
                  content: t("cap_error"),
                  pointing: "below",
                }
              : null
          }
        ></Form.Input>
      </Form.Group>
      <Form.Input
        fluid
        label={t("indirizzo")}
        placeholder={t("indirizzo")}
        name="indirizzo"
        onChange={handleFieldChange}
        value={datiCliente.indirizzo}
        error={
          errors.indirizzo
            ? {
                content: t("indirizzo_error"),
                pointing: "below",
              }
            : null
        }
      ></Form.Input>
      {props.fatturazione &&
        datiCliente.nazione === "IT" &&
        datiCliente.tipo === "P" && (
          <Form.Input
            fluid
            //label={t("Codice Fiscale")}
            label={
              datiCliente.tipo === "P" ? t("Codice Fiscale") : "Partita IVA"
            }
            placeholder={t("Codice Fiscale")}
            name="cf"
            onChange={handleFieldChange}
            value={datiCliente.cf}
            error={
              errors.cf
                ? {
                    content: t("cf_error"),
                    pointing: "below",
                  }
                : null
            }
          ></Form.Input>
        )}
      {datiCliente.tipo === "A" && datiCliente.nazione === "IT" && (
        <Form.Group>
          <Form.Input
            width={10}
            //label={t("Codice Fiscale")}
            label={t("Partita_IVA")}
            placeholder={t("Partita_IVA")}
            name="cf"
            onChange={handleFieldChange}
            value={datiCliente.cf}
            error={
              errors.cf
                ? {
                    content: t("cf_error"),
                    pointing: "below",
                  }
                : null
            }
          ></Form.Input>
          <Form.Input
            width={6}
            //label={t("Codice Fiscale")}
            label={t("Codice_Univoco")}
            placeholder={t("Codice_Univoco")}
            name="codUnivoco"
            onChange={handleFieldChange}
            value={datiCliente.codUnivoco}
            error={
              errors.codUnivoco
                ? {
                    content: t("codUnivoco_error"),
                    pointing: "below",
                  }
                : null
            }
          ></Form.Input>
        </Form.Group>
      )}
      {datiCliente.tipo === "A" && datiCliente.nazione !== "IT" && (
        <Form.Input
          expanded
          //label={t("Codice Fiscale")}
          label={t("Partita_IVA")}
          placeholder={t("Partita_IVA")}
          name="cf"
          onChange={handleFieldChange}
          value={datiCliente.cf}
          error={
            errors.cf
              ? {
                  content: t("cf_error"),
                  pointing: "below",
                }
              : null
          }
        ></Form.Input>
      )}

      <Form.Input
        fluid
        label={t("email")}
        placeholder={t("email")}
        name="email"
        onChange={handleFieldChange}
        value={datiCliente.email}
        error={
          errors.email
            ? {
                content: t("email_error"),
                pointing: "below",
              }
            : null
        }
      ></Form.Input>
      <Grid>
        {windowSize.width >= 600 && (
          <Grid.Row>
            <Grid.Column computer={isCheckout ? 4 : 3}>
              <label>Country Code</label>
              <Dropdown
                placeholder="Country Code"
                fluid
                name="prefisso"
                onChange={handleFieldChange}
                value={datiCliente.prefisso}
                search
                selection
                options={listaNazioniDial}
                error={
                  errors.prefisso
                    ? {
                        content: t("prefisso_error"),
                        pointing: "below",
                      }
                    : null
                }
              />
            </Grid.Column>
            <Grid.Column computer={isCheckout ? 12 : 13}>
              <Form.Input
                fluid
                label={t("cellulare")}
                placeholder={t("cellulare")}
                name="telefono"
                onChange={handleFieldChange}
                value={datiCliente.telefono}
                error={
                  errors.telefono
                    ? {
                        content: t("cellulare_error"),
                        pointing: "below",
                      }
                    : null
                }
              ></Form.Input>
            </Grid.Column>
          </Grid.Row>
        )}
        {windowSize.width < 600 && (
          <>
            <Grid.Row>
              <Grid.Column>
                <label>Country Code</label>
                <Dropdown
                  placeholder="Country Code"
                  fluid
                  name="prefisso"
                  onChange={handleFieldChange}
                  value={datiCliente.prefisso}
                  search
                  selection
                  options={listaNazioniDial}
                  error={
                    errors.prefisso
                      ? {
                          content: t("prefisso_error"),
                          pointing: "below",
                        }
                      : null
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column style={{ marginTop: "-14px" }}>
                <Form.Input
                  fluid
                  label={t("cellulare")}
                  placeholder={t("cellulare")}
                  name="telefono"
                  onChange={handleFieldChange}
                  value={datiCliente.telefono}
                  error={
                    errors.telefono
                      ? {
                          content: t("cellulare_error"),
                          pointing: "below",
                        }
                      : null
                  }
                ></Form.Input>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
      {richiestaHotel && (
        <div style={{ paddingTop: "14px" }}>
          <Form.Dropdown
            fluid
            label={t("Seleziona_Hotel")}
            selection
            options={listaHotel}
            placeholder={t("Seleziona_Hotel")}
            name="hotel"
            onChange={handleFieldChange}
            value={datiCliente.hotel}
            search
            error={
              errors.hotel
                ? {
                    content: t("hotel_error"),
                    pointing: "below",
                  }
                : null
            }
          />
        </div>
      )}
      {isCheckout && (
        <div style={{ paddingTop: "14px" }}>
          <Form.Field
            control={Select}
            fluid
            label={t("preferenza_lingua")}
            selection
            options={listaLingue}
            placeholder={t("lingua")}
            name="lingua"
            onChange={handleFieldChange}
            value={datiCliente.lingua}
            style={{ marginBottom: "14px" }}
            search
          />
        </div>
      )}
    </Form>
  );
};

export default FormClienteNew;
