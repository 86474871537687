import React, { useState } from "react";
import { Segment, Dropdown, Grid, Button, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

function ListaCategorieTesto(props) {
  const [lista] = useState(() => {
    const tipiArray = props.tipologie.map((x, idx) => {
      const el = {
        key: x.idTipologiaCorsoWeb,
        value: x.idTipologiaCorsoWeb,
        text: x.titolo,
      };
      return el;
    });
    return tipiArray;
  });

  return (
    <Segment style={{ marginTop: "0px" }} className="padding_5_lr">
      <Grid columns={2} stackable>
        <Grid.Column
          textAlign="left"
          verticalAlign="middle"
          width={10}
          className="padding_0_lr"
        >
          <label>Tipo di corso</label>
          <Dropdown
            clearable
            options={lista}
            placeholder="TUTTI I CORSI"
            selection
            onChange={props.onTipologiaChange}
            value={props.idTipologia}
            fluid
          ></Dropdown>
        </Grid.Column>
        {/* <Grid.Column textAlign="right" width={6} verticalAlign="middle">
          <label>Visualizzazione</label>
          <Button
            active={props.stileVisualizzazione === "g"}
            icon
            onClick={() => {
              props.onStileVisualizzazioneChange("g");
            }}
          >
            <Icon name="th" />
          </Button>
          <Button
            active={props.stileVisualizzazione === "l"}
            icon
            onClick={() => {
              props.onStileVisualizzazioneChange("l");
            }}
          >
            <Icon name="list" />
          </Button>
        </Grid.Column> */}
      </Grid>
    </Segment>
  );
}

ListaCategorieTesto.propTypes = {
  tipologie: PropTypes.array.isRequired,
  idTipologia: PropTypes.number,
  stileVisualizzazione: PropTypes.string.isRequired,
  onTipologiaChange: PropTypes.func.isRequired,
  onStileVisualizzazioneChange: PropTypes.func.isRequired,
};

export default ListaCategorieTesto;
