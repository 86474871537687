import React from "react";
import { Dropdown } from "semantic-ui-react";

const RitroviFastBooking = (props) => {
  //console.log(listaRitrovi);
  const ritroviOptions = props.listaRitrovi.map((r) => {
    return {
      key: r,
      text: r,
      value: r,
    };
  });

  ritroviOptions.splice(0, 0, {
    key: "-1",
    text: props.textNP,
    value: "-1",
  });

  return (
    <>
      <Dropdown
        fluid
        selection
        options={ritroviOptions}
        value={props.selezione}
        onChange={(val1, val2, val3) => {
          props.onChange(val2.value);
        }}
      ></Dropdown>
    </>
  );
};

export default RitroviFastBooking;
