import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
//import { expireReducer } from "redux-persist-expire";
import thunk from "redux-thunk";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import rootReducer from "./reducers";
import initialState from "./reducers/initialState";
import expireReducer from "redux-persist-expire";

// Middleware: Redux Persist Config
const persistConfig = {
  // Root?
  key: "root",
  // Storage Method (React Native)
  storage,
  // Whitelist (Save Specific Reducers)
  whitelist: ["appReducer", "scuolaReducer", "corsi", "cartReducer"], //["appReducer", "scuolaReducer", "corsi", "cartReducer"],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: ["apiCallsInProgress"],
  transforms: [
    expireReducer("cartReducer", {
      expireSeconds: 60 * 30,
      autoExpire: true,
      expiredState: {
        righePrivate: [],
        righeCollettive: [],
        righeFastBooking: [],
        righeBuoni: [],
        pagamento: {},
      },
    }),
    expireReducer("corsi", {
      expireSeconds: 60 * 30,
      autoExpire: true,
      expiredState: { lista: [], sessioni: {}, servizi: [] },
    }),
    expireReducer("privateReducer", {
      expireSeconds: 60 * 30,
      autoExpire: true,
      expiredState: { disponibilita: [], disponibilitaFast: [] },
    }),
    expireReducer("appReducer", {
      expireSeconds: 60 * 30,
      autoExpire: true,
      expiredState: {},
    }),
  ],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Redux: Store
const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
);

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

// Exports
export { store, persistor };

// import { createStore, applyMiddleware, compose } from "redux";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import rootReducer from "./reducers";
// import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
// import thunk from "redux-thunk";

// const persistConfig = {
//   key: "root",
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export default function configureStore(initialState) {
//   const composeEnhancers =
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//   return createStore(
//     persistedReducer,
//     initialState,
//     composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
//   );
// }
