import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Segment,
  Grid,
  Image,
  Divider,
  Icon,
  Portal,
  Header,
  Button,
  Popup,
} from "semantic-ui-react";
import defaultImgUomo from "../../assets/images/people/AvatarMaestro.jpg";
import defaultImgDonna from "../../assets/images/people/AvatarMaestra.jpg";

function GruppoSelezione(props) {
  const [showInfo, setShowInfo] = useState([]);

  useEffect(() => {
    const infoArray = [];
    for (let i = 0; i < props.elementi.length; i++) {
      infoArray.push(false);
    }
    setShowInfo(infoArray);
  }, []);

  function handleClick(ev) {
    props.onChange(ev.currentTarget.attributes.name.textContent);
  }

  const getDefaultSrc = (ev) => {
    switch (ev.target.alt) {
      case "M":
        ev.target.src = defaultImgUomo;
        break;
      case "F":
        ev.target.src = defaultImgDonna;
        break;
      default:
        ev.target.style.display = "none";
        break;
    }
  };

  return (
    <div style={{ marginTop: "10px", marginBottom: "5px" }}>
      <label>{props.titolo}</label>
      <Grid centered={props.centered}>
        {props.elementi.map((el, idx) => (
          <Grid.Column
            style={{
              width: el.width === undefined ? `${props.width}px` : el.width,
              paddingRight: "7px",
            }}
            key={el.key}
          >
            <Segment
              textAlign="center"
              onClick={handleClick}
              style={{
                padding: "5px",
                marginTop: "5px",
                backgroundColor: "white",
              }}
              className={`select_element ${
                el.key === props.selezione ? "active" : ""
              } `}
              name={el.key}
            >
              {el.img !== undefined && (
                <>
                  <Image
                    src={el.img}
                    alt={el.alt}
                    style={{
                      margin: "0 auto",
                      height:
                        props.altezzaImmagine === undefined
                          ? "auto"
                          : props.altezzaImmagine,
                    }}
                    onError={getDefaultSrc}
                  ></Image>

                  <Divider style={{ margin: "2px" }} />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "10px",
                      color: "black",
                      height:
                        props.altezzaTesto === undefined
                          ? "auto"
                          : props.altezzaTesto,
                    }}
                  >
                    {el.text}
                  </p>
                </>
              )}

              {el.img === undefined && (
                <p
                  style={{
                    textAlign: "center",
                    display: "table-cell",
                    verticalAlign: "middle",
                    fontSize: "14px",
                    color: "black",
                    height: "85px",
                  }}
                >
                  {el.text}
                </p>
              )}

              {el.additionalText !== undefined && (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    color: "black",
                  }}
                >
                  {el.additionalText}
                </p>
              )}
            </Segment>
            {/* <Portal open={showInfo[idx]}>
              <Segment
                style={{
                  marginLeft: "10%",
                  marginTop: "140px",
                  //position: "fixed",
                  //top: "50%",
                  zIndex: 1000,
                  backgroundColor: "white",
                  marginRight: "10%",
                  //maxHeight: "330px",
                  //overflowY: "auto",
                }}
              >
                <Header>{el.text}</Header>
                <p
                  style={{ color: "rgb(48,48,48,0.87)" }}
                  dangerouslySetInnerHTML={{
                    __html: el.infoString,
                  }}
                ></p>
                <div
                  style={{ textAlign: "center", color: "rgb(48,48,48,0.87)" }}
                >
                  <Button
                    content="CHIUDI"
                    primary
                    onClick={() => {
                      const infoArr = [...showInfo];
                      infoArr[idx] = false;
                      setShowInfo(infoArr);
                    }}
                  />
                </div>
              </Segment>
            </Portal> */}
            {el.infoString !== undefined && (
              <Popup
                style={{ marginLeft: "14px" }}
                content={
                  <>
                    <Header>{el.text}</Header>
                    <p
                      style={{ color: "rgb(48,48,48,0.87)" }}
                      dangerouslySetInnerHTML={{
                        __html: el.infoString,
                      }}
                    ></p>
                  </>
                }
                on="click"
                trigger={
                  <Segment
                    basic
                    textAlign="center"
                    style={{
                      cursor: "pointer",
                      paddingTop: "0px",
                      marginTop: "0px",
                    }}
                    // onClick={() => {
                    //   const infoArr = [...showInfo];
                    //   for (let i = 0; i < infoArr.length; i++) {
                    //     if (i !== idx) {
                    //       infoArr[i] = false;
                    //     } else {
                    //       infoArr[i] = !infoArr[i];
                    //     }
                    //   }

                    //   // infoArr[idx] = true;
                    //   setShowInfo(infoArr);
                    // }}
                  >
                    <Icon name="circle info" color="blue" size="big"></Icon>
                  </Segment>
                }
              />
            )}
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
}

GruppoSelezione.propTypes = {
  elementWidth: PropTypes.number,
  elementHeigth: PropTypes.number,
  elementi: PropTypes.array.isRequired,
  selezione: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  titolo: PropTypes.string.isRequired,
};

export default GruppoSelezione;
