import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Segment, Grid, Header, Icon, Form, Button } from "semantic-ui-react";
import useWindowSize from "../../utils/useWindowSize";
import Prezzo2 from "./Prezzo2";
import * as calendarUtils from "../../utils/calendarUtils";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PacchettoDettagliTipo0 from "./pacchetti/PacchettoDettagliTipo0";
import PacchettoDettagliTipo1 from "./pacchetti/PacchettoDettagliTipo1";
import PacchettoDettagliTipo2 from "./pacchetti/PacchettoDettagliTipo2";
import PacchettoCalendarioTipo2 from "./pacchetti/PacchettoCalendarioTipo2";
import PacchettoCalendarioTipo1 from "./pacchetti/PacchettoCalendarioTipo1";
import InfoWeb from "../common/InfoWeb";

function DettaglioCorsoPacchetto({
  corso,
  sessioniCorso,
  datiScuola,
  ...props
}) {
  /////////////////////////
  //STATE
  const [numGiorniDisponibili, setNumGiorniDisponibili] = useState([]);
  const [numGiorniScelti, setNumGiorniScelti] = useState(0);
  const [numAllieviDisponibili] = useState([
    { key: 1, text: "1", value: 1 },
    { key: 2, text: "2", value: 2 },
    { key: 3, text: "3", value: 3 },
  ]);
  const [numAllievi, setNumAllievi] = useState(1);
  const [avantiEnabled, setAvantiEnabled] = useState(false);

  const windowSize = useWindowSize();
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);
  const [dateSelezionate, setDateSelezionate] = useState([]);
  const [righeSelezionate, setRigheSelezionate] = useState([]);

  //////////////////
  //EFFECTS
  useEffect(() => {
    if (sessioniCorso.sessioni === undefined) return;
    const listino = sessioniCorso.sessioni[0].listinoPacchetto.sort((a, b) => {
      if (a.numGiorni < b.numGiorni) {
        return -1;
      } else return 1;
    });

    const giorniArray = [];
    if (corso.tipoSelezioneGiornate === 2) {
      giorniArray.push({
        key: -1,
        text: "SELEZIONARE",
        value: -1,
      });
    }

    for (let i = 0; i < listino.length; i++) {
      if (listino[i].Prezzo !== 0) {
        giorniArray.push({
          key: listino[i].numGiorni,
          text: listino[i].numGiorni,
          value: listino[i].numGiorni,
        });
      }
    }
    setNumGiorniDisponibili(giorniArray);
    if (corso.tipoSelezioneGiornate === 2) {
      setNumGiorniScelti(-1);
    } else {
      setNumGiorniScelti(giorniArray[giorniArray.length - 1]?.value ?? 0);
    }
  }, []);

  useEffect(() => {
    if (numGiorniScelti !== undefined && dateSelezionate !== undefined) {
      setAvantiEnabled(isAvantiEnabled());
    }
  }, [numGiorniScelti, dateSelezionate]);

  //////////////////////////
  //HANDLERS
  function handleNumGiorniChange(value) {
    setNumGiorniScelti(value);
    if (corso.tipoSelezioneGiornate === 2) {
      setDateSelezionate([]);
      setRigheSelezionate([]);
    }
  }

  const handleDataSelezionata = (date) => {
    if (corso.tipoSelezioneGiornate === 1) {
      setDateSelezionate([date]);
      setRigheSelezionate([
        sessioniCorso.sessioni[0].righe.find((riga) =>
          calendarUtils.isSameDay(moment(riga.data), date)
        ),
      ]);
    }
    if (corso.tipoSelezioneGiornate === 2) {
      //verifico se la data è già in elenco e in quel caso la rimuovo, altrimenti aggiungo
      const dayIndex = dateSelezionate.findIndex((day) =>
        calendarUtils.isSameDay(day, date)
      );
      if (dayIndex > -1) {
        setDateSelezionate(
          dateSelezionate.filter((dt) => !calendarUtils.isSameDay(dt, date))
        );
        setRigheSelezionate(
          righeSelezionate.filter(
            (riga) => !calendarUtils.isSameDay(moment(riga.data), date)
          )
        );
      } else {
        if (dateSelezionate.length >= numGiorniScelti) {
          alert(
            `Sono già state scelte ${numGiorniScelti} giornate! Togliere un altra data o se disponibili variare il numero di giorni`
          );
          return;
        }
        setDateSelezionate(
          [...dateSelezionate, date].sort((a, b) => {
            if (a.isBefore(b)) {
              return -1;
            } else {
              return 1;
            }
          })
        );
        setRigheSelezionate(
          [
            ...righeSelezionate,
            sessioniCorso.sessioni[0].righe.find((riga) =>
              calendarUtils.isSameDay(moment(riga.data), date)
            ),
          ].sort((a, b) => {
            if (moment(a.data).isBefore(moment(b.data))) {
              return -1;
            } else {
              return 1;
            }
          })
        );
      }
    }
  };

  ///////////////////////
  // HELPERS
  function listino() {
    return sessioniCorso.sessioni[0].listinoPacchetto.find(
      (x) => parseInt(numGiorniScelti) === x.numGiorni
    );
  }

  const isAvantiEnabled = () => {
    if (corso.tipoSelezioneGiornate === 0) return true;
    if (corso.tipoSelezioneGiornate === 1) {
      return numGiorniScelti > 0 && dateSelezionate.length > 0;
    }
    if (corso.tipoSelezioneGiornate === 2) {
      return numGiorniScelti > 0 && dateSelezionate.length === numGiorniScelti;
    }
  };

  return (
    <Segment
      basic
      className="padding_0_lr"
      style={{ marginTop: "0px", paddingTop: "0px", paddingLeft: "0px" }}
    >
      <Grid columns="equal" stackable textAlign="left">
        <Grid.Column
          style={{
            paddingLeft: windowSize.width > 600 ? "0px" : "14px",
            margin: "0px",
            paddingTop: "0px",
          }}
          mobile="16"
          largeScreen="10"
        >
          {corso.tipoSelezioneGiornate === 1 && (
            <PacchettoCalendarioTipo1
              numGiorniScelti={numGiorniScelti}
              sessioni={sessioniCorso}
              handleDataSelezionata={handleDataSelezionata}
              dateSelezionate={dateSelezionate}
            />
          )}
          {corso.tipoSelezioneGiornate === 2 && (
            <PacchettoCalendarioTipo2
              numGiorniScelti={numGiorniScelti}
              sessioni={sessioniCorso}
              handleDataSelezionata={handleDataSelezionata}
              dateSelezionate={dateSelezionate}
            />
          )}

          <div
            style={{
              marginLeft: windowSize.width > 600 ? "2em" : "2px",
              marginTop: "2em",
            }}
          >
            <InfoWeb
              testi={datiScuola.infoWeb.filter((x) => x.sezione === 0)}
              sezione={0}
            />
          </div>
        </Grid.Column>
        <Grid.Column mobile="16" largeScreen="6">
          <Segment>
            <Header as="h3">
              <Icon name="calendar alternate outline"></Icon>
              <Header.Content>{t("dettagli_corso")}</Header.Content>
            </Header>
            {corso.tipoSelezioneGiornate === 0 && (
              <PacchettoDettagliTipo0
                t={t}
                numGiorniDisponibili={numGiorniDisponibili}
                numGiorniScelti={numGiorniScelti}
                numAllieviDisponibili={numAllieviDisponibili}
                numAllievi={numAllievi}
                handleNumGiorniChange={handleNumGiorniChange}
                setNumAllievi={setNumAllievi}
              />
            )}
            {corso.tipoSelezioneGiornate === 1 && (
              <PacchettoDettagliTipo1
                t={t}
                numGiorniDisponibili={numGiorniDisponibili}
                numGiorniScelti={numGiorniScelti}
                numAllieviDisponibili={numAllieviDisponibili}
                numAllievi={numAllievi}
                handleNumGiorniChange={handleNumGiorniChange}
                handleDataSelezionata={handleDataSelezionata}
                setNumAllievi={setNumAllievi}
                righeSelezionate={righeSelezionate}
              />
            )}
            {corso.tipoSelezioneGiornate === 2 && (
              <PacchettoDettagliTipo2
                t={t}
                numGiorniDisponibili={numGiorniDisponibili}
                numGiorniScelti={numGiorniScelti}
                numAllieviDisponibili={numAllieviDisponibili}
                numAllievi={numAllievi}
                handleNumGiorniChange={handleNumGiorniChange}
                handleDataSelezionata={handleDataSelezionata}
                setNumAllievi={setNumAllievi}
                righeSelezionate={righeSelezionate}
              />
            )}

            <Prezzo2
              numGiorniScelti={numGiorniScelti}
              corso={corso}
              sessioniCorso={sessioniCorso}
              sessioneSelezionata={undefined}
              numAllievi={numAllievi}
              datiScuola={datiScuola}
            />
          </Segment>
          <Segment basic textAlign="center">
            <Button
              disabled={!avantiEnabled}
              primary
              onClick={() => {
                props.onAvantiClick(
                  corso.tipoSelezioneGiornate === 1 ||
                    corso.tipoSelezioneGiornate === 2
                    ? dateSelezionate[0].format("YYYY-MM-DD")
                    : undefined,
                  numGiorniScelti,
                  numGiorniScelti,
                  numAllievi,
                  listino().prezzo,
                  listino().prezzoPag,
                  listino().prezzoListino,
                  corso.tipoSelezioneGiornate === 2
                    ? dateSelezionate.map((d) => d.format("YYYY-MM-DD"))
                    : undefined
                );
              }}
              size="big"
            >
              <Icon name="cart" />
              {t("avanti")}
            </Button>
          </Segment>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}

DettaglioCorsoPacchetto.propTypes = {
  corso: PropTypes.object.isRequired,
  sessioniCorso: PropTypes.array.isRequired,
};

export default DettaglioCorsoPacchetto;
