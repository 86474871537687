import React, { useEffect } from "react";
import { Segment, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ScalapayFrase from "../common/ScalapayFrase";

function Prezzo({
  numGiorniScelti,
  corso,
  sessioniCorso,
  sessioneSelezionata,
  numAllievi,
  datiScuola,
  primaData,
  ...props
}) {
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  const DicituraSconto = () => {
    return (
      <div className="skiwork-testi dicitura-sconto">
        {t("Prezzo_scontato")}
      </div>
    );
  };

  if (numGiorniScelti < 1) return <></>;
  const listino =
    corso.tipoVenditaWeb === 1
      ? sessioniCorso.sessioni[0].listinoPacchetto
      : sessioneSelezionata.listino;

  let sessioniEffettive = 0;
  if (corso.tipoVenditaWeb === 1) {
    sessioniEffettive = numGiorniScelti;
  } else {
    //recupero l'index della prima data
    let startDayIndex = 0;
    for (let i = 0; i < sessioneSelezionata.listaDateEffettive.length; i++) {
      if (
        primaData.format("YYYY-MM-DD") ===
        moment(sessioneSelezionata.listaDateEffettive[i]).format("YYYY-MM-DD")
      ) {
        startDayIndex = i;
        break;
      }
    }

    for (let i = 0; i < numGiorniScelti; i++) {
      //conto e sommo le sessioni della giornata
      for (let r = 0; r < sessioneSelezionata.righe.length; ++r) {
        if (
          moment(sessioneSelezionata.righe[r].data).format("DD/MM/YYYY") ===
          moment(
            sessioneSelezionata.listaDateEffettive[startDayIndex + i]
          ).format("DD/MM/YYYY")
          //primaData.clone().add(i, "days").format("DD/MM/YYYY")
        ) {
          sessioniEffettive++;
        }
      }
    }
  }

  const l = listino.find((x) => parseInt(sessioniEffettive) === x.numGiorni);

  let acconto = false;
  if (l.prezzo !== l.prezzoPag) {
    acconto = true;
  }
  if (numAllievi === 1) {
    if (acconto) {
      return (
        <Segment
          textAlign={datiScuola.tipoBanca_2 > 0 ? "left" : "right"}
          basic
          style={{ marginTop: "0px" }}
        >
          <Divider />
          <label>
            {" "}
            {l.prezzo < l.prezzoListino
              ? t("prezzo_online")
              : t("prezzo_corso")}
          </label>
          <p className="prezzo_parziale">
            {l.prezzo < l.prezzoListino && (
              <span>
                <span
                  style={{ textDecoration: "line-through", marginRight: "7px" }}
                >
                  {datiScuola.valuta} {l.prezzoListino.toFixed(2)}
                </span>
              </span>
            )}
            <span>
              {datiScuola.valuta} {l.prezzo.toFixed(2)}
            </span>
          </p>
          <label>{t("acconto")}</label>
          <p className="prezzo_totale">
            {datiScuola.valuta} {l.prezzoPag.toFixed(2)}
          </p>
          {datiScuola.tipoBanca_2 > 0 && (
            <div className="scalapay_corso">
              oppure 3 rate da
              <span
                style={{
                  fontWeight: "bold",
                  marginLeft: "2px",
                  marginRight: "3px",
                }}
              >
                € {(l.prezzo / 3).toFixed(2)}
              </span>
              senza interessi{" "}
              <img
                src={require(`../../assets/images/scalapay/badge.png`)}
                alt="scalapay logo"
                className="scalapay-logo-small"
              />
            </div>
          )}
          <DicituraSconto></DicituraSconto>
        </Segment>
      );
    } else {
      return (
        <Segment
          textAlign={datiScuola.tipoBanca_2 > 0 ? "left" : "right"}
          basic
        >
          <Divider />

          <label>
            {l.prezzo >= l.prezzoListino ? t("prezzo") : t("prezzo_online")}
          </label>
          <p className="prezzo_totale">
            {l.prezzo < l.prezzoListino && (
              <span style={{ textDecoration: "line-through" }}>
                {datiScuola.valuta} {l.prezzoListino.toFixed(2)}
              </span>
            )}
            <span>
              {datiScuola.valuta} {l.prezzo.toFixed(2)}
            </span>
          </p>
          {datiScuola.tipoBanca_2 > 0 && (
            <ScalapayFrase
              prezzo={(parseFloat(l.prezzo) / 3).toFixed(2)}
              end={false}
            />
          )}
          <DicituraSconto></DicituraSconto>
        </Segment>
      );
    }
  } else {
    if (acconto) {
      return (
        <Segment
          textAlign={datiScuola.tipoBanca_2 > 0 ? "left" : "right"}
          basic
        >
          <Divider />
          <label>
            {l.prezzo < l.prezzoListino ? t("prezzo_online") : t("prezzo")}
          </label>
          <p className="prezzo_parziale">
            {l.prezzo < l.prezzoListino && (
              <span
                style={{ textDecoration: "line-through", marginRight: "7px" }}
              >
                {numAllievi} x {datiScuola.valuta} {l.prezzoListino.toFixed(2)}
              </span>
            )}
            <span>{numAllievi} x </span>
            {datiScuola.valuta} {l.prezzo.toFixed(2)}
          </p>
          <label>{t("acconto")}</label>
          <p className="prezzo_parziale">
            <span>{numAllievi} x </span>
            {datiScuola.valuta} {l.prezzoPag.toFixed(2)}
          </p>
          <Divider></Divider>
          <p className="prezzo_parziale">
            PREZZO TOTALE {datiScuola.valuta}{" "}
            {(parseFloat(l.prezzo) * parseInt(numAllievi)).toFixed(2)}
          </p>
          <p className="prezzo_totale">
            {t("acconto")} {datiScuola.valuta}{" "}
            {(parseFloat(l.prezzoPag) * parseInt(numAllievi)).toFixed(2)}
          </p>
          {datiScuola.tipoBanca_2 > 0 && (
            <div className="scalapay_corso">
              oppure 3 rate da
              <span
                style={{
                  fontWeight: "bold",
                  marginLeft: "2px",
                  marginRight: "3px",
                }}
              >
                € {(l.prezzo / 3).toFixed(2)}
              </span>
              senza interessi{" "}
              <img
                src={require(`../../assets/images/scalapay/badge.png`)}
                alt="scalapay logo"
                className="scalapay-logo-small"
              />
            </div>
          )}
          <DicituraSconto></DicituraSconto>
        </Segment>
      );
    } else {
      return (
        <Segment
          textAlign={datiScuola.tipoBanca_2 > 0 ? "left" : "right"}
          basic
        >
          <Divider />
          <label>
            {l.prezzo >= l.prezzoListino ? t("prezzo") : t("prezzo_online")}
          </label>

          <p className="prezzo_parziale">
            {l.prezzo < l.prezzoListino && (
              <span
                style={{ textDecoration: "line-through", marginRight: "14px" }}
              >
                {numAllievi} x {datiScuola.valuta} {l.prezzoListino.toFixed(2)}{" "}
              </span>
            )}
            <span>{numAllievi} x </span>
            {datiScuola.valuta} {l.prezzo.toFixed(2)}
          </p>
          <Divider></Divider>
          <p className="prezzo_totale">
            <span>TOT </span>
            {l.prezzo < l.prezzoListino && (
              <span
                style={{ textDecoration: "line-through", marginRight: "14px" }}
              >
                {datiScuola.valuta}{" "}
                {(parseFloat(l.prezzoListino) * parseInt(numAllievi)).toFixed(
                  2
                )}
              </span>
            )}
            <span>
              {datiScuola.valuta}{" "}
              {(parseFloat(l.prezzo) * parseInt(numAllievi)).toFixed(2)}
            </span>
          </p>
          {datiScuola.tipoBanca_2 > 0 && (
            <ScalapayFrase
              prezzo={(
                (parseFloat(l.prezzo) * parseInt(numAllievi)) /
                3
              ).toFixed(2)}
              end={false}
            />
          )}
          <DicituraSconto></DicituraSconto>
        </Segment>
      );
    }
  }
}

export default Prezzo;
