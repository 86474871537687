import React, { useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import GruppoSelezione from "../common/GruppoSelezione";
import PropTypes from "prop-types";
import * as skiworkUtils from "../../utils/skiworkUtils";

const RiquadroPreferenze = (props) => {
  const [listaPrefs, setListaPrefs] = useState([]);
  useEffect(() => {
    const prefs = [];
    prefs.push({
      key: props.specialita.id.toString(),
      img: require(`../../assets/images/specialita/${
        props.specialita.simbolo === null ? "0" : props.specialita.simbolo
      }.jpg`),
      text: skiworkUtils.getDescrizioneSpecialita(
        props.specialita,
        props.idLinguaInUso
      ),
    });

    if (props.maestro === undefined) {
      //Sesso
      switch (props.sesso) {
        case "M":
          prefs.push({
            text: "M",
            img: require(`../../assets/images/people/Male.jpg`),
            key: "M",
          });
          break;
        case "F":
          prefs.push({
            text: "F",
            img: require(`../../assets/images/people/Female.jpg`),
            key: "F",
          });
          break;
        default:
          prefs.push({
            text: "M or F",
            img: require(`../../assets/images/defaults/free.jpg`),
            key: "",
          });
      }
      //Lingua
      if (props.lingua === "") {
        prefs.push({
          text: props.t("qualsiasi_lingua"),
          img: require(`../../assets/images/defaults/free.jpg`),
          key: "X",
        });
      } else {
        prefs.push({
          text: props.lingua,
          img: require(`../../assets/images/flags/${props.idLingua}.jpg`),
          key: props.lingua,
        });
      }
    } else {
      prefs.push({
        text: props.maestro.titoloWeb,
        img: `https://skiwork.blob.core.windows.net/webimg/${props.codScuola.toUpperCase()}/Maestri/${
          props.maestro.picture
        }`,
        key: props.maestro.titoloWeb,
        alt: props.maestro.sesso,
        // tipo: "maestro",
      });
    }

    setListaPrefs(prefs);
  }, []);

  return (
    <>
      <Segment>
        <div>{props.t("PREFERENZE_SCELTE")}</div>
        <GruppoSelezione
          altezzaTesto="27px"
          altezzaImmagine="68px"
          width={97}
          elementi={listaPrefs}
          onChange={() => {}}
          centered={true}
        ></GruppoSelezione>
      </Segment>
    </>
  );
};

RiquadroPreferenze.propTypes = {
  idLinguaInUso: PropTypes.number,
  maestro: PropTypes.object,
  specialita: PropTypes.object.isRequired,
  lingua: PropTypes.string.isRequired,
  idLingua: PropTypes.number.isRequired,
  sesso: PropTypes.string.isRequired,
};

export default RiquadroPreferenze;
