import React, { useState, useEffect } from "react";
import { Container, Loader, Segment, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import TestataPagina from "../common/TestataPagina";
import * as buoniApi from "../../api/buoniApi";
import { Redirect } from "react-router-dom";
import * as appActions from "../../redux/actions/appActions";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import { isSafari } from "react-device-detect";

const BuonoVoucher = ({
  appData,
  codScuola,
  datiScuola,
  codBuono,
  loading,
  lang,

  loadSchoolData,
  setSlug,
  setLang,
  clearAppData,

  ...props
}) => {
  const [infoBuono, setInfoBuono] = useState({});
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (codScuola !== appData.slug || lang !== appData.lang) {
      clearAppData();
      loadSchoolData(codScuola, lang);
      setSlug(codScuola);
      setLang(lang);
    }
  }, []);

  useEffect(() => {
    const getInfo = async () => {
      setFetching(true);

      const result = await buoniApi.GetInfoBuono(
        datiScuola.id,
        codBuono,
        isSafari
      );
      setInfoBuono(result);
      setFetching(false);
    };
    getInfo().catch(console.error);
  }, [datiScuola.id]);

  return (
    <Container>
      <div className="head-offset"></div>
      <TestataPagina
        titolo="BUONI/VOUCHER"
        icon="gift"
        sottoTitolo="Ecco il tuo buono/voucher!"
      />
      {(loading || fetching) && <Loader active size="huge"></Loader>}
      {!loading && !fetching && infoBuono !== null && (
        <Segment className="padding_0_lr contenitore-grande">
          {!isSafari && (
            <embed
              src={`https://skiwork.blob.core.windows.net/buoni/buono-${datiScuola.codiceScuola}${codBuono}.pdf`}
              type="application/pdf"
              style={{ width: "100%", height: "1280px" }}
            ></embed>
          )}
          {isSafari && (
            <embed
              src={`staticfiles/buonitmp/buono-${datiScuola.codiceScuola}${codBuono}.pdf`}
              type="application/pdf"
              style={{ width: "100%", height: "1280px" }}
            ></embed>
          )}
        </Segment>
      )}
      {!loading && !fetching && infoBuono === null && (
        <Segment className="padding_0_lr contenitore-grande">
          <Segment inverted color="red">
            <Header as="h3" textAlign="center">
              BUONO NON TROVATO
            </Header>
          </Segment>
        </Segment>
      )}
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    codBuono: ownProps.match.params.codiceBuono,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  setLang: appActions.setLang,
  setSlug: appActions.setSlug,
  loadSchoolData: scuolaActions.loadSchoolData,
  clearAppData: appActions.clearAppData,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuonoVoucher);
