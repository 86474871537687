export async function handleResponse(response) {
  if (response.status === 204) return null;
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}

export function getBaseUrl() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    return "https://localhost:44354/api/";
  //return "https://192.168.1.123:44354/api/";
  else return "https://www.skiwork.shop/api/";
  //else return "https://skiworkbookstaging.azurewebsites.net/api/";
}

export function getBaseUrlPortal() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
    return "https://localhost:7272/api/";
  //return "https://192.168.1.123:44354/api/";
  //else return "https://www.skiwork.shop/api/";
  else return "https://skiworkbookstaging.azurewebsites.net/api/";
}
