import React from "react";
import { Icon, Form } from "semantic-ui-react";
import styles from "./PacchettoDettagliTipo1.module.css";
import moment from "moment";

const PacchettoDettagliTipo1 = ({
  t,
  numGiorniDisponibili,
  numGiorniScelti,
  numAllieviDisponibili,
  numAllievi,
  handleNumGiorniChange,
  handleDataSelezionata,
  setNumAllievi,
  righeSelezionate,
  ...props
}) => {
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Dropdown
          selection
          label={t("numero_giorni")}
          options={numGiorniDisponibili}
          value={numGiorniScelti}
          onChange={(e, data) => {
            handleNumGiorniChange(data.value);
          }}
        ></Form.Dropdown>
        <Form.Dropdown
          selection
          label={t("numero_allievi")}
          options={numAllieviDisponibili}
          value={numAllievi}
          onChange={(e, data) => {
            setNumAllievi(data.value);
          }}
        ></Form.Dropdown>
      </Form.Group>
      {righeSelezionate.length === 0 && numGiorniScelti > 0 && (
        <div className={styles.seleziona_data}>
          <Icon name="arrow left" size="large" />
          <span>SCEGLI LA PRIMA DATA</span>
        </div>
      )}
      {righeSelezionate.length > 0 && (
        <div className="field">
          <label>Prima giornata scelta</label>

          {righeSelezionate.map((r) => (
            <div className={styles.programma_container}>
              <span>{moment(r.data).format("DD/MM/YYYY")}</span>
            </div>
          ))}
        </div>
      )}
    </Form>
  );
};

export default PacchettoDettagliTipo1;
