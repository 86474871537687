import React from "react";
import {
  Card,
  Button,
  Image,
  Transition,
  Segment,
  Icon,
} from "semantic-ui-react";
import defaultImgCorso from "../../assets/images/defaults/defaultCourse.jpg";
import PropTypes from "prop-types";
import useWindowSize from "../../utils/useWindowSize";
import { useTranslation } from "react-i18next";

function SchedaCorsoCard({ t, ...props }) {
  function addDefaultSrc(ev) {
    console.log("IMAGE NOT FOUND");
    ev.target.src = defaultImgCorso;
  }

  //const { i18n, t } = useTranslation();

  const windowSize = useWindowSize();

  const picUrl = `https://skiwork.blob.core.windows.net/webimg/${props.codScuola}/${props.corso.picture}`;

  return (
    <Transition visible={props.isVisible} animation="swing up" duration={500}>
      <Card
        key={props.corso.idCorso}
        className="schedaCorsoCard"
        style={windowSize.width < 600 ? { width: "100%" } : { width: "31%" }}
      >
        <div
          style={{
            width: "100%",
            height: "0",
            paddingBottom: "66%",
            position: "relative",
          }}
        >
          <img
            onError={addDefaultSrc}
            src={picUrl}
            alt="category"
            className="skiwork-image"
            width="100%"
            height="100%"
          ></img>
        </div>
        <Card.Content style={{ border: "0" }}>
          <Card.Header
            as="h3"
            className="skiwork-card-header"
            dangerouslySetInnerHTML={{
              __html: props.corso.titolo,
            }}
          ></Card.Header>
          <Card.Meta
            className="skiwork-card-subheader"
            dangerouslySetInnerHTML={{
              __html: props.corso.sottoTitolo,
            }}
          ></Card.Meta>
          <Card.Description
            dangerouslySetInnerHTML={{
              __html: props.corso.descrizione,
            }}
          ></Card.Description>
        </Card.Content>
        <Card.Content
          style={{ marginBottom: "40px", border: "0" }}
          textAlign="center"
        >
          <Segment
            style={{
              position: "absolute",
              bottom: "0px",
              top: "auto",
              left: "0",
              right: "0",
            }}
            basic
            textAlign="center"
          >
            <Button
              primary
              onClick={() => {
                props.onSelect(props.corso.idCorso);
              }}
              size="big"
            >
              <Icon name="cart"></Icon>
              {t("scegli")}
            </Button>
          </Segment>
        </Card.Content>
      </Card>
    </Transition>
  );
}
SchedaCorsoCard.propTypes = {
  codScuola: PropTypes.string.isRequired,
  corso: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default SchedaCorsoCard;
