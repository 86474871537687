import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Header, Divider, Segment } from "semantic-ui-react";
import * as appActions from "../redux/actions/appActions";
import slogo from "../assets/images/slogo.png";

function About(props) {
  useEffect(() => {
    props.clearAppData();
  }, [props]);

  return (
    <Container fluid textAlign="center">
      <Segment basic></Segment>
      <img
        src={slogo}
        style={{ width: "256px", height: "auto" }}
        alt="SKIWORK 2021"
      />
      <Divider></Divider>
      <Segment basic>
        <Header as="h1">SKIWORK</Header>
        <Header as="h2">SKI SCHOOL CLOUD BOOKING SYSTEM</Header>
      </Segment>
    </Container>
  );
}

function mapStateToProps(state, ownProps) {
  return {};
}

const mapDispatchToProps = {
  clearAppData: appActions.clearAppData,
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
