import React from "react";
import PropTypes from "prop-types";
import { Segment, Header, Icon, Divider } from "semantic-ui-react";

function TestataPagina(props) {
  return (
    <Segment
      style={{ paddingBottom: "10px" }}
      className="padding_5_lr skiwork-header-pagina"
    >
      <Header textAlign="left" style={{ marginBottom: "0px" }} as="h2">
        <Icon name={props.icon} />
        <Header.Content>
          {props.titolo}
          {props.sottoTitolo !== undefined && (
            <Header.Subheader>
              <span style={{ fontSize: "18px" }}>{props.sottoTitolo}</span>
            </Header.Subheader>
          )}
        </Header.Content>
      </Header>
      {/* <Divider style={{ marginBottom: "0px" }} /> */}
    </Segment>
  );
}

TestataPagina.propTypes = {
  titolo: PropTypes.string.isRequired,
  sottoTitolo: PropTypes.string,
  icon: PropTypes.string.isRequired,
};

export default TestataPagina;
