import React, { useEffect } from "react";
import TestataPagina from "../common/TestataPagina";
import { Segment, Header, Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as appActions from "../../redux/actions/appActions";
import { connect } from "react-redux";

const CheckInOk = ({ appData, ...props }) => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);
  return (
    <Container>
      <div className="head-offset"></div>
      <TestataPagina
        titolo="CHECK IN"
        sottoTitolo={t("check_in_buon_fine")}
        icon="address book"
      ></TestataPagina>
      <Segment className="padding_5_lr" textAlign="center">
        <Segment inverted color="green">
          <Header as="h3" textAlign="center">
            {t("CHECKIN_BUON_FINE")}
          </Header>
        </Segment>
      </Segment>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadSchoolData: scuolaActions.loadSchoolData,
  setSlug: appActions.setSlug,
  setLang: appActions.setLang,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckInOk);
