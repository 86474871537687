import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  ListItem,
  ListContent,
  Form,
  Segment,
  FormField,
  Header,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const SchedaAllievoCorsoRooming = ({
  datiAllievo,
  datiScuola,
  datiCorso,
  ...props
}) => {
  const [anniNascita, setAnniNascita] = useState([]);
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    const currentYear = moment().year();
    const minEta = datiCorso.minAnni;
    const maxEta = datiCorso.maxAnni;

    const tempArray = new Array(maxEta - minEta);

    for (
      let year = currentYear - minEta;
      year >= currentYear - maxEta;
      year--
    ) {
      tempArray[currentYear - minEta - year] = {
        key: year,
        text: year,
        value: year,
      };
    }
    setAnniNascita(tempArray);

    datiAllievo.Index = props.num - 1;
  }, []);

  function handleFieldChange(e, value) {
    props.onChange(props.num, value.name, value.value);
  }

  return (
    <ListItem>
      <ListContent>
        <Segment
          basic
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px",
            gap: "7px",
          }}
        >
          <Header>{props.num})</Header>
          <Form.Input
            style={{ margin: "0px" }}
            placeholder="Nome *"
            value={datiAllievo.nome}
            onChange={handleFieldChange}
            name="nome"
            error={
              props?.errors?.nome
                ? {
                    content: t("nome_error"),
                    pointing: "below",
                  }
                : null
            }
          />

          <Form.Input
            placeholder="Cognome *"
            value={datiAllievo.cognome}
            onChange={handleFieldChange}
            name="cognome"
            error={
              props?.errors?.cognome
                ? {
                    content: t("cognome_error"),
                    pointing: "below",
                  }
                : null
            }
          />
          <Form.Select
            fluid
            //   label={t("anno_nascita")}
            options={anniNascita}
            placeholder={t("anno_nascita")}
            name="anno"
            value={datiAllievo.AnnoNascita}
            onChange={handleFieldChange}
            //   error={
            //     props.errors.anno
            //       ? {
            //           content: t("anno_error"),
            //           //pointing: "below",
            //         }
            //       : null
            //   }
          />
        </Segment>
      </ListContent>
    </ListItem>
  );
};

export default SchedaAllievoCorsoRooming;
