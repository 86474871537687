import * as types from "../actions/actionTypes";
import initialState from "./initialState";
// import { act } from "react-dom/test-utils";

export default function appReducer(state = initialState.appReducer, action) {
  switch (action.type) {
    case types.BOOK_SET_CORSO_DETTAGLI:
      return { ...state, corsoDettagli: action.dettagli };
    case types.BOOK_SET_PREFS_CORSO:
      return { ...state, prefsCorso: action.prefs };
    case types.BOOK_SET_PREFS_PRIVATE:
      return {
        ...state,
        prefsPrivate: action.prefs,
      };
    case types.SET_LANG:
      return {
        ...state,
        lang: action.lang,
      };
    case types.SET_SLUG:
      return {
        ...state,
        slug: action.slug,
      };
    case types.SET_AGENZIA:
      return {
        ...state,
        agenzia: action.agenzia,
      };
    case types.BOOK_RESET:
      const stateTmp = { ...state };
      delete stateTmp.corsoDettagli;
      delete stateTmp.prefsCorso;
      return {
        ...stateTmp,
      };
    case types.APP_CLEAR:
      return {};
    default:
      return state;
  }
}
