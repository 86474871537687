import React from "react";
import { Segment, List } from "semantic-ui-react";
import PropTypes from "prop-types";
import SingoloServizio from "./SingoloServizio";

function TipologiaServizio(props) {
  function handleClick(servizio, prezzo, descrizione) {
    if (
      props.tipoServizi.servizi.find((s) => s.idServizio === servizio)
        ?.obbligatorio
    )
      return;
    props.onServizioAggiunto(props.numAllievo, servizio, prezzo, descrizione);
  }

  if (props.tipoServizi.titoloCategoria.length === 0) {
    return (
      <>
        <List divided relaxed size="mini">
          {props.tipoServizi.servizi.map((s) => (
            <SingoloServizio
              id={s.idServizio}
              titolo={s.titolo}
              sottoTitolo={s.sottoTitolo}
              descrizione={s.descrizione}
              prezzo={s.prezzoAlGiorno ? s.prezzo * props.numGiorni : s.prezzo}
              selezionato={
                props.serviziAttivi.findIndex((x) => x.id === s.idServizio) >= 0
              }
              simbolo={s.simbolo}
              onClick={handleClick}
              key={s.idServizio}
              datiScuola={props.datiScuola}
            />
          ))}
        </List>
      </>
    );
  } else {
    return <Segment>Tipologia</Segment>;
  }
}

TipologiaServizio.propTypes = {
  numGiorni: PropTypes.number.isRequired,
  numAllievo: PropTypes.number.isRequired,
  tipoServizi: PropTypes.object.isRequired,
  onServizioAggiunto: PropTypes.func.isRequired,
  serviziAttivi: PropTypes.array.isRequired,
};

export default TipologiaServizio;
