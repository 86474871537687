import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import useWindowSize from "../utils/useWindowSize";
import { Image, Icon } from "semantic-ui-react";

function Footer({ datiScuola }) {
  const history = useHistory();
  const mailLink = `mailto:${datiScuola.eMail}`;
  const phoneLink = `tel:${datiScuola.telefono}`;
  const logoImgPath = `scuole/${datiScuola.codiceScuola}/logofooter.png`;
  const windowsSize = useWindowSize();

  if (datiScuola.hasOwnProperty("id")) {
    return (
      <div className="skiwork-footer">
        {/* <div style={{ float: "left" }} className="footer-logo-container">
          <Image
            onError={(i) => (i.target.style.display = "none")}
            className="footer-logo"
            src={logoImgPath}
            size="small"
            onClick={() => {
              history.back();
            }}
          ></Image>
        </div> */}
        {windowsSize.width > 600 && (
          <>
            <div
              style={{ margin: "0 auto" }}
              className="footer-logo-container-up"
            >
              <Image
                onError={(i) => (i.target.style.display = "none")}
                style={{ margin: "0 auto" }}
                className="footer-logo-up"
                src={logoImgPath}
                size="small"
                onClick={() => {
                  history.back();
                }}
              ></Image>
            </div>
            <div
              style={{ position: "absolute", left: "0" }}
              className="footer-logo-container"
            >
              <Image
                onError={(i) => (i.target.style.display = "none")}
                className="footer-logo"
                src={logoImgPath}
                size="small"
                onClick={() => {
                  history.back();
                }}
              ></Image>
            </div>
          </>
        )}

        <p style={{ marginBottom: "0px" }}>
          Copyright <Icon name="copyright outline" />
          2024 - {datiScuola.nomeScuola}
        </p>

        <p style={{ marginTop: "0px" }}>
          {datiScuola.indirizzo} - {datiScuola.cap} {datiScuola.citta} (
          {datiScuola.provincia}){" "}
          <a href={phoneLink} style={{ border: "none" }}>
            {datiScuola.telefono}
          </a>{" "}
          -{" "}
          <a href={mailLink} style={{ border: "none" }}>
            {datiScuola.eMail}
          </a>
          - {datiScuola.sito} - PI {datiScuola.partitaIva}
        </p>
      </div>
    );
  } else {
    return (
      <div className="skiwork-footer">
        <p>
          Copyright(c) 2022 - SKIWORK - DCS Srl Sport Tech -{" "}
          <a href="https://www.skiwork.it">www.skiwork.it</a>
        </p>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    datiScuola: state.scuolaReducer,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
