import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function corsiReducer(state = initialState.corsi, action) {
  switch (action.type) {
    // case types.LOAD_CORSI_SUCCESS:
    //   return { ...state, lista: action.listaCorsi };
    case types.LOAD_SESSIONICORSI_SUCCESS:
      return { ...state, sessioni: action.listaSessioni };
    // case types.LOAD_SERVIZICORSO_SUCCESS:
    //   return { ...state, servizi: action.servizi };
    case types.APP_CLEAR:
      return { lista: [], sessioni: {}, servizi: [] };
    default:
      return state;
  }
}
