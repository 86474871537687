import { combineReducers } from "redux";
//import appData from "./appReducer";
import apiCallsInProgress from "./apiStatusReducer";
import scuolaReducer from "./scuolaReducer";
import appReducer from "./appReducer";
import corsi from "./corsiReducer";
import privateReducer from "./privateReducer";
import cartReducer from "./cartReducer";

const allReducer = combineReducers({
  appReducer,
  scuolaReducer,
  corsi,
  privateReducer,
  cartReducer,
  apiCallsInProgress,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    state = undefined;
  }

  return allReducer(state, action);
};

export default rootReducer;
