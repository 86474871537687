import * as types from "./actionTypes";
import * as scuolaApi from "../../api/scuolaApi";

import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadSchoolDataSuccess(clubData) {
  return {
    type: types.LOAD_SCUOLA_DATA_SUCCESS,
    clubData,
  };
}

export function clearDatiScuola() {
  return {
    type: types.SCUOLA_CLEAR,
  };
}

export function loadSchoolData(codScuola, lang) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return scuolaApi
      .getDatiScuola(codScuola, lang)
      .then((c) => {
        dispatch(loadSchoolDataSuccess(c));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

// export function loadNazioniSuccess(nazioni) {
//   return {
//     type: types.LOAD_NAZIONI_SUCCESS,
//     nazioni,
//   };
// }

// export function loadNazioni(codScuola) {
//   return function (dispatch) {
//     dispatch(beginApiCall());
//     return scuolaApi
//       .getNazioni(codScuola)
//       .then((c) => {
//         dispatch(loadNazioniSuccess(c));
//       })
//       .catch((error) => {
//         dispatch(apiCallError(error));
//         throw error;
//       });
//   };
// }
