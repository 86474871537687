import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function scuolaReducer(
  state = initialState.scuolaReducer,
  action
) {
  switch (action.type) {
    case types.LOAD_SCUOLA_DATA_SUCCESS:
      return action.clubData;
    // case types.LOAD_NAZIONI_SUCCESS:
    //   return { ...state, nazioni: action.nazioni };
    case types.CHECKOUT_PAGAMENTO_SUCCESS:
      return { ...state, checkoutInfo: action.info };
    case types.APP_CLEAR:
      return {};
    case types.SCUOLA_CLEAR:
      return {};

    default:
      return state;
  }
}
