import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Container,
  Segment,
  Header,
  Icon,
  Loader,
  List,
  Divider,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import TestataPagina from "../common/TestataPagina";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as appActions from "../../redux/actions/appActions";
import * as checkoutApi from "../../api/checkoutApi";
import { useTranslation } from "react-i18next";

function TransactionOk({
  loadSchoolData,
  appData,
  datiScuola,
  setSlug,
  setLang,
  codScuola,
  lang,
  ...props
}) {
  ////////////////////////////////
  //STATE
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [orderData, setOrderData] = useState({});
  const [sparkasseCheck, setSparkasseCheck] = useState(false);

  const history = useHistory();
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);

  //////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    //Verifico se ci sono i dati di almeno una delle tipologie di banca disponibili
    // - order_id -> phoenix, axepta
    // - panc -> sparkasse
    if (
      !urlParams.has("order_id") &&
      !urlParams.has("panc") &&
      !urlParams.has("orderid")
    ) {
      setError(true);
    }

    if (codScuola !== appData.slug || lang !== appData.lang) {
      loadSchoolData(codScuola, lang);
      setSlug(codScuola);
      setLang(lang);
    }
  }, []);

  useEffect(() => {
    const loadOrder = async () => {
      if (!datiScuola.hasOwnProperty("id")) {
        loadSchoolData(codScuola, lang);
      }

      if (datiScuola.hasOwnProperty("id")) {
        var urlParams = new URLSearchParams(
          props.location.search.toLowerCase()
        );
        //CR, Axepta
        if (urlParams.has("order_id")) {
          setOrderId(urlParams.get("order_id").toUpperCase());
          setIsLoading(true);
          var res = await checkoutApi.GetOrderDetails(
            datiScuola.id,
            urlParams.get("order_id")
          );

          setOrderData(res);
        }

        //SIA
        if (urlParams.has("orderid")) {
          console.log("SIA");
          setOrderId(urlParams.get("orderid").toUpperCase());
          setIsLoading(true);
          var res = await checkoutApi.GetOrderDetails(
            datiScuola.id,
            urlParams.get("orderid")
          );

          setOrderData(res);
        }

        //SPARKASSE, check aggiuntivi perchè manca l'url di ack...
        if (urlParams.has("panc")) {
          setIsLoading(true);

          if (sparkasseCheck) return;

          setSparkasseCheck(true);
          const resSparkasse = await checkoutApi.CheckTrSparkasse(
            datiScuola.codiceScuola,
            urlParams.get("transid"),
            urlParams.get("c")
          );

          if (resSparkasse.ok !== true) {
            history.push(`/${lang}/${codScuola}/checkout/KO`);
          }

          setOrderId(urlParams.get("transid").toUpperCase());
          var res = await checkoutApi.GetOrderDetails(
            datiScuola.id,
            urlParams.get("order_id")
          );

          setOrderData(res);
        }
      }
    };

    loadOrder();
    setIsLoading(false);
  }, [datiScuola]);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  ////////////////////////////////
  //HANDLERS
  function openPdf(file) {
    window.open(`https://skiwork.blob.core.windows.net/ricevute/${file}`);
  }

  if (error) {
    return (
      <Container>
        <div className="head-offset"></div>
        <TestataPagina
          titolo={t("PAGAMENTO")}
          sottoTitolo="VERIFICA DATI"
          icon="credit card"
        ></TestataPagina>
        <Segment className="padding_5_lr" textAlign="center">
          <Segment inverted color="red">
            <Header as="h3" textAlign="center">
              RICHIESTA NON CORRETTA
            </Header>
          </Segment>
        </Segment>
      </Container>
    );
  } else {
    if (isLoading || orderData.listaVoucher === undefined) {
      return <Loader active size="huge" />;
    } else {
      return (
        <Container>
          <div className="head-offset"></div>
          <TestataPagina
            titolo={t("PAGAMENTO")}
            sottoTitolo={t("pagamento_buon_fine")}
            icon="credit card"
          ></TestataPagina>
          <Segment className="padding_5_lr" textAlign="center">
            <Segment inverted color="green">
              <Header as="h3" textAlign="center">
                {t("PAGAMENTO_BUON_FINE")}
              </Header>
            </Segment>

            <Segment basic>
              <Header as="h3">{t("ricevuta_pagamento")}</Header>
              <Icon
                name="file pdf outline"
                size="massive"
                color="red"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  openPdf(`${orderId}_RICEVUTA.PDF`);
                  // openPdf(
                  //   `${orderId}_${
                  //     orderData.soloPagamento ? "1" : "RICEVUTA"
                  //   }.PDF`
                  // );
                }}
              />
              <Divider />
              {/* {!orderData.conETicket && (
                <>
                  <Header as="h3" textAlign="left">
                    {t("voucher_lezioni")}
                  </Header>
                  <List>
                    {orderData.listaVoucher.map((v) => {
                      return (
                        <List.Item>
                          <Header
                            as="h4"
                            textAlign="center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              openPdf(v.toUpperCase());
                            }}
                          >
                            <Icon
                              name="file pdf outline"
                              size="big"
                              color="red"
                            />
                            <Header.Content>{v.toUpperCase()}</Header.Content>
                          </Header>
                        </List.Item>
                      );
                    })}
                  </List>
                </>
              )} */}
              {orderData.conETicket && (
                <>
                  <Header as="h3" textAlign="center">
                    Skischool e-ticketing system
                  </Header>
                  <Segment basic as="h4">
                    {t("verifica_email_ticket")}
                  </Segment>
                </>
              )}
            </Segment>
          </Segment>
        </Container>
      );
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadSchoolData: scuolaActions.loadSchoolData,
  setSlug: appActions.setSlug,
  setLang: appActions.setLang,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionOk);
