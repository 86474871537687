import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as scuolaActions from "../redux/actions/scuolaActions";
import * as appActions from "../redux/actions/appActions";
import {
  Loader,
  Container,
  Segment,
  Button,
  Icon,
  Transition,
  IconGroup,
} from "semantic-ui-react";
import TestataPagina from "./common/TestataPagina";
import useWindowSize from "../utils/useWindowSize";
import { useTranslation } from "react-i18next";

function Entry({
  datiScuola,
  codScuola,
  lang,
  loading,
  appData,
  loadSchoolData,
  setLang,
  setSlug,
  clearAppData,
  bookReset,
}) {
  const windowsSize = useWindowSize();
  const [redirectToCollettive, setRedirectToCollettive] = useState(false);
  const [redirectToPrivate, setRedirectToPrivate] = useState(false);
  const [redirectToOffertePrivate, setRedirectToOffertePrivate] =
    useState(false);
  const [redirectToPrivateNoOfferte, setRedirectToPrivateNoOfferte] =
    useState(false);
  const [redirectToFast, setRedirectToFast] = useState(false);
  const [redirectToPagamenti, setRedirectToPagamenti] = useState(false);
  const [redirectToCheckin, setRedirectToCheckin] = useState(false);
  const [redirectToBuoni, setRedirectToBuoni] = useState(false);
  const [sceltaPrivateMode, setSceltaPrivateMode] = useState(""); //["P", "F", "multi", "none"
  const [sceltaPrivateVisibile, setSceltaPrivateVisibile] = useState(false);

  ////////////////////////////////
  //HOOKS
  //custom translation?
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  useEffect(() => {
    if (codScuola !== appData.slug) {
      clearAppData();
      setSlug(codScuola);
    } else {
      bookReset();
    }
    loadSchoolData(codScuola, lang);
    setLang(lang);

    i18n.changeLanguage(lang.toLowerCase());
  }, [codScuola, lang, loadSchoolData]);

  function handleScelta(tipo) {
    if (tipo === "C") {
      setRedirectToCollettive(true);
    }
    if (tipo === "P") {
      setRedirectToPrivate(true);
    }
    if (tipo === "PO") {
      setRedirectToOffertePrivate(true);
    }
    if (tipo === "PN") {
      setRedirectToPrivateNoOfferte(true);
    }
    if (tipo === "F") {
      setRedirectToFast(true);
    }
    if (tipo === "A") {
      setRedirectToPagamenti(true);
    }
    if (tipo === "Gift") {
      setRedirectToBuoni(true);
    }
    if (tipo === "Checkin") {
      setRedirectToCheckin(true);
    }
  }

  const isPrivateSceltaMultipla = () => {
    return (
      (datiScuola.attivoPrivate && datiScuola.attivoFastBooking) ||
      (datiScuola.attivoPrivate && datiScuola.attivoOffertePrivate) ||
      (datiScuola.attivoFastBooking && datiScuola.attivoOffertePrivate)
    );
  };

  if (loading) {
    return <Loader active size="huge"></Loader>;
  } else {
    return (
      <Container>
        {redirectToCollettive && (
          <Redirect to={`/${lang}/${codScuola}/collettive/`} push />
        )}

        {redirectToPrivate && (
          <Redirect to={`/${lang}/${codScuola}/private/`} push />
        )}

        {redirectToOffertePrivate && (
          <Redirect to={`/${lang}/${codScuola}/private?offers=true`} push />
        )}

        {redirectToPrivateNoOfferte && (
          <Redirect to={`/${lang}/${codScuola}/private?offers=false`} push />
        )}
        {redirectToFast && <Redirect to={`/${lang}/${codScuola}/fast/`} push />}
        {redirectToPagamenti && (
          <Redirect to={`/${lang}/${codScuola}/pagamenti/`} push />
        )}
        {redirectToBuoni && (
          <Redirect to={`/${lang}/${codScuola}/buoni/`} push />
        )}
        {redirectToCheckin && (
          <Redirect to={`/${lang}/${codScuola}/checkin/`} push />
        )}

        <div className="head-offset"></div>
        <TestataPagina
          titolo={`ONLINE BOOKING`}
          icon="columns"
          sottoTitolo={t("segreteria_virtuale")}
        />
        <Segment textAlign="center" className="contenitore-grande">
          {datiScuola.attivoCollettive && (
            <Button
              size="huge"
              primary
              onClick={() => handleScelta("C")}
              style={{ marginBottom: "10px" }}
              fluid={windowsSize.width < 600}
            >
              <Icon name="group"></Icon>
              {t("lezioni_collettive")}
            </Button>
          )}

          {(datiScuola.attivoPrivate ||
            datiScuola.attivoFastBooking ||
            datiScuola.attivoOffertePrivate) && (
            <Button
              size="huge"
              primary
              onClick={() => {
                if (
                  datiScuola.attivoPrivate &&
                  !datiScuola.attivoFastBooking &&
                  !datiScuola.attivoOffertePrivate
                ) {
                  handleScelta("P");
                }
                if (
                  !datiScuola.attivoPrivate &&
                  datiScuola.attivoFastBooking &&
                  !datiScuola.attivoOffertePrivate
                ) {
                  handleScelta("F");
                }
                if (
                  !datiScuola.attivoPrivate &&
                  !datiScuola.attivoFastBooking &&
                  datiScuola.attivoOffertePrivate
                ) {
                  handleScelta("PO");
                }
                if (isPrivateSceltaMultipla()) {
                  setSceltaPrivateVisibile(!sceltaPrivateVisibile);
                }
              }}
              style={{ marginBottom: "10px" }}
              fluid={windowsSize.width < 600}
            >
              <Icon name="user" />
              {t("lezioni_private")}
            </Button>
          )}
          {/* {datiScuola.attivoFastBooking && (
            <Button
              size="huge"
              primary
              onClick={() => handleScelta("F")}
              style={{ marginBottom: "10px" }}
              fluid={windowsSize.width < 600}
            >
              <Icon name="fast forward" />
              {t("lezioni_fast")}
            </Button>
          )} */}
          {datiScuola.attivoPagamenti && (
            <Button
              size="huge"
              primary
              onClick={() => handleScelta("A")}
              style={{ marginBottom: "10px" }}
              fluid={windowsSize.width < 600}
            >
              <Icon name="credit card" />
              {t("pagamenti")}
            </Button>
          )}
          {datiScuola.attivoCheckIn && (
            <Button
              size="huge"
              primary
              onClick={() => handleScelta("Checkin")}
              style={{ marginBottom: "10px" }}
              fluid={windowsSize.width < 600}
            >
              <Icon name="address book" />
              {t("Check-In")}
            </Button>
          )}
          {datiScuola.attivoBuoni && (
            <Button
              size="huge"
              primary
              onClick={() => handleScelta("Gift")}
              style={{ marginBottom: "10px" }}
              fluid={windowsSize.width < 600}
            >
              <Icon name="gift" />
              {t("buoni-voucher")}
            </Button>
          )}
        </Segment>

        <Transition.Group animation="drop" duration={500}>
          {sceltaPrivateVisibile && (
            <Segment style={{ textAlign: "center" }}>
              <Segment inverted className="skiwork-header">
                {t("come_procedere")}
              </Segment>
              {datiScuola.attivoPrivate && !datiScuola.attivoOffertePrivate && (
                <Button
                  size="huge"
                  primary
                  onClick={() => handleScelta("P")}
                  style={{ marginBottom: "10px" }}
                  fluid={windowsSize.width < 600}
                >
                  <Icon name="user" />
                  {t("crea_programma")}
                </Button>
              )}
              {datiScuola.attivoPrivate && datiScuola.attivoOffertePrivate && (
                <Button
                  size="huge"
                  primary
                  onClick={() => handleScelta("PN")}
                  style={{ marginBottom: "10px" }}
                  fluid={windowsSize.width < 600}
                >
                  <Icon name="user" />
                  {t("crea_programma")}
                </Button>
              )}
              {datiScuola.attivoOffertePrivate && (
                <Button
                  size="huge"
                  primary
                  onClick={() => handleScelta("PO")}
                  style={{ marginBottom: "10px" }}
                  fluid={windowsSize.width < 600}
                >
                  <IconGroup>
                    <Icon name="exclamation" />
                  </IconGroup>
                  {t("offerte")}
                </Button>
              )}
              {datiScuola.attivoFastBooking && (
                <Button
                  size="huge"
                  primary
                  onClick={() => handleScelta("F")}
                  style={{ marginBottom: "10px" }}
                  fluid={windowsSize.width < 600}
                >
                  <Icon name="fast forward" />
                  {t("disp_immediata")}
                </Button>
              )}
            </Segment>
          )}
        </Transition.Group>
      </Container>
    );
  }
}
function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  setLang: appActions.setLang,
  setSlug: appActions.setSlug,
  clearAppData: appActions.clearAppData,
  loadSchoolData: scuolaActions.loadSchoolData,
  bookReset: appActions.bookReset,
};

export default connect(mapStateToProps, mapDispatchToProps)(Entry);
