import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";

const containerStyle = {
  width: "100%",
  height: "400px",
  marginBottom: "14px",
};

// const center = {
//   lat: 46.3025523,
//   lng: 11.6032354,
// };

function Mappa(props) {
  // const [map, setMap] = React.useState(null);

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null);
  // }, []);

  return (
    // <LoadScript googleMapsApiKey="AIzaSyBqTFuG-axAp4tCsTTNRWJeWtJolQ0iyes">
    <LoadScript googleMapsApiKey="AIzaSyAFdrQqWp1hf7ur2U1kzXz38AtNM6tQ3Lo">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{ lat: props.lat, lng: props.lng }}
        zoom={props.zoom}
        mapTypeId={"hybrid"}
      >
        <>
          <Marker
            position={{
              lat: props.lat,
              lng: props.lng,
            }}
            animation
            title={props.ritrovo}
          ></Marker>
        </>
      </GoogleMap>
    </LoadScript>
  );
}

Mappa.propTypes = {
  ritrovo: PropTypes.string.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
};

export default Mappa;
