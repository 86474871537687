import React from "react";
import { List, Segment, Image, Icon, Popup } from "semantic-ui-react";
import PropTypes from "prop-types";

function SingoloServizio(props) {
  const selectedStyle = {
    backgroundColor: "#e6ffe3",
    padding: "6px",
    border: "1px solid #84ff75",
  };

  const unSelectedStyle = {
    backgroundColor: "white",
    padding: "7px",
    //border: "1px solid #84ff75",
  };

  return (
    <List.Item>
      <Segment
        style={props.selezionato ? selectedStyle : unSelectedStyle}
        basic
      >
        <List.Content as="a" verticalAlign="middle" style={{ width: "100%" }}>
          <List.Header>
            {props.selezionato ? (
              <List.Icon
                name="check square outline"
                size="big"
                verticalAlign="middle"
                onClick={() => {
                  props.onClick(props.id, props.prezzo, props.titolo);
                }}
              />
            ) : (
              <List.Icon
                name="square outline"
                size="big"
                verticalAlign="middle"
                onClick={() => {
                  props.onClick(props.id, props.prezzo, props.titolo);
                }}
              />
            )}
            {props.simbolo > 0 && (
              <Image
                avatar
                src={require(`../../assets/images/servizi/${props.simbolo}.jpg`)}
                verticalAlign="middle"
              ></Image>
            )}
            <span
              onClick={() => {
                props.onClick(props.id, props.prezzo, props.titolo);
              }}
            >
              {props.titolo}
            </span>
            <Popup
              content={props.descrizione}
              header={props.titolo}
              trigger={
                <Icon
                  style={{ float: "right", marginLeft: "1.5rem" }}
                  name="info circle"
                  size="large"
                  color="primary"
                />
              }
            />

            <span style={{ float: "right" }}>
              {props.datiScuola.valuta} {props.prezzo.toFixed(2)}
            </span>
            {/* <span style={{ float: "right", marginRight: "1rem" }}>I</span> */}
          </List.Header>
          <List.Description
            style={{ marginLeft: "38px" }}
            onClick={() => {
              props.onClick(props.id, props.prezzo, props.titolo);
            }}
          >
            <span style={{ fontSize: "0.8rem" }}>{props.sottoTitolo}</span>
          </List.Description>
        </List.Content>
      </Segment>
    </List.Item>
  );
}

SingoloServizio.propTypes = {
  id: PropTypes.number.isRequired,
  titolo: PropTypes.string.isRequired,
  sottoTitolo: PropTypes.string.isRequired,
  prezzo: PropTypes.number.isRequired,
  selezionato: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SingoloServizio;
