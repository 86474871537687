import React from "react";
import PropTypes from "prop-types";
import { Label } from "semantic-ui-react";

function FormError(props) {
  return (
    <p>
      <Label pointing={props.pointingDirection} basic color="red">
        {props.errore}
      </Label>
    </p>
  );
}

FormError.propTypes = {
  errore: PropTypes.string.isRequired,
};

export default FormError;
