import React from "react";

const ScalapayFrase = ({ prezzo, end, ...props }) => {
  return (
    <div
      className="scalapay_corso"
      style={{ justifyContent: end ? "end" : "start" }}
    >
      oppure 3 rate da
      <span
        style={{
          fontWeight: "bold",
          marginLeft: "2px",
          marginRight: "3px",
        }}
      >
        € {prezzo}
      </span>
      senza interessi{" "}
      <img
        src={require(`../../assets/images/scalapay/badge.png`)}
        alt="scalapay logo"
        className="scalapay-logo-small"
      />
    </div>
  );
};

export default ScalapayFrase;
