import React from "react";
import { Grid } from "semantic-ui-react";
import useWindowSize from "../../utils/useWindowSize";

const InfoWeb = ({ testi, sezione, ...props }) => {
  const windowsSize = useWindowSize();

  if (testi === undefined) {
    return null;
  }

  testi = testi.filter(
    (t) =>
      t.titolo !== "" &&
      t.titolo !== null &&
      t.titolo !== undefined &&
      t.icona !== "" &&
      t.icona !== null &&
      t.icona !== undefined
  );

  if (testi.length === 0) return null;

  if (windowsSize.width < 600) {
    return (
      <Grid className="InfoWebContainer">
        {testi.map((t) => (
          <Grid.Row>
            <InfoWebElement columns={16} testo={t.titolo} icona={t.icona} />
          </Grid.Row>
        ))}
      </Grid>
    );
  } else {
    switch (sezione) {
      case 0:
        switch (testi.length) {
          case 1:
          case 2:
            return (
              <Grid className="InfoWebContainer">
                {testi.map((t) => (
                  <Grid.Row>
                    <InfoWebElement
                      columns={16}
                      testo={t.titolo}
                      icona={t.icona}
                    />
                  </Grid.Row>
                ))}
              </Grid>
            );
          case 3:
            return (
              <Grid className="InfoWebContainer">
                {(testi[0].titolo.length > 180 ||
                  testi[1].titolo.length > 180) && (
                  <>
                    <Grid.Row>
                      <InfoWebElement
                        columns={16}
                        testo={testi[0].titolo}
                        icona={testi[0].icona}
                      />
                    </Grid.Row>
                    <Grid.Row>
                      <InfoWebElement
                        columns={16}
                        testo={testi[1].titolo}
                        icona={testi[1].icona}
                      />
                    </Grid.Row>
                  </>
                )}
                {testi[0].titolo.length <= 180 &&
                  testi[1].titolo.length <= 180 && (
                    <Grid.Row style={{ textAlign: "left" }}>
                      <InfoWebElement
                        columns={8}
                        testo={testi[0].titolo}
                        icona={testi[0].icona}
                      />
                      <InfoWebElement
                        columns={8}
                        testo={testi[1].titolo}
                        icona={testi[1].icona}
                      />
                    </Grid.Row>
                  )}
                <Grid.Row>
                  <InfoWebElement
                    columns={16}
                    testo={testi[2].titolo}
                    icona={testi[2].icona}
                  />
                </Grid.Row>
              </Grid>
            );
          default:
            return (
              <Grid className="InfoWebContainer">
                <Grid.Row>
                  <InfoWebElement
                    columns={8}
                    testo={testi[0].titolo}
                    icona={testi[0].icona}
                  />
                  <InfoWebElement
                    columns={8}
                    testo={testi[1].titolo}
                    icona={testi[1].icona}
                  />
                </Grid.Row>
                <Grid.Row>
                  <InfoWebElement
                    columns={16}
                    testo={testi[2].titolo}
                    icona={testi[2].icona}
                  />
                </Grid.Row>
              </Grid>
            );
        }
      default:
        return (
          <Grid className="InfoWebContainer">
            {testi.map((t) => (
              <Grid.Row>
                <InfoWebElement columns={16} testo={t.titolo} icona={t.icona} />
              </Grid.Row>
            ))}
          </Grid>
        );
    }
  }
};

const InfoWebElement = ({ columns, icona, testo, ...props }) => {
  return (
    <Grid.Column width={columns}>
      <div
        style={{
          display: "flex",
          gap: "14px",
          alignItems: "flex-start",
        }}
      >
        <img
          src={require(`../../assets/images/infoweb/${icona}.png`)}
          alt=" "
          style={{ objectFit: "contain" }}
        ></img>
        <div dangerouslySetInnerHTML={{ __html: testo }}></div>
      </div>
    </Grid.Column>
  );
};

export default InfoWeb;
