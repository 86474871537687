import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function cartReducer(state = initialState.cartReducer, action) {
  switch (action.type) {
    case types.CART_ADD_PRIVATA:
      return { ...state, righePrivate: [...state.righePrivate, action.riga] };
    case types.CART_ADD_COLLETTIVA:
      return {
        ...state,
        righeCollettive: [...state.righeCollettive, action.riga],
      };
    case types.CART_ADD_FAST:
      return {
        ...state,
        righeFastBooking: [...state.righeFastBooking, action.riga],
      };
    case types.CART_ADD_BUONO:
      return {
        ...state,
        righeBuoni: [...state.righeBuoni, action.riga],
      };
    case types.CART_SUBMIT:
      return {
        ...state,
        righeCollettive: [],
        righePrivate: [],
      };
    case types.CART_SET_REFERRER:
      return {
        ...state,
        referrer: action.referrer,
      };
    case types.CART_REMOVE_COLLETTIVA:
      const cloneRigheC = [...state.righeCollettive];
      cloneRigheC.splice(action.index, 1);

      return {
        ...state,
        righeCollettive: cloneRigheC,
      };
    case types.CART_REMOVE_PRIVATA:
      const cloneRigheP = [...state.righePrivate];
      cloneRigheP.splice(action.index, 1);

      return {
        ...state,
        righePrivate: cloneRigheP,
      };
    case types.CART_REMOVE_FAST:
      //debugger;
      const cloneRigheF = [...state.righeFastBooking];
      cloneRigheF.splice(action.index, 1);

      return {
        ...state,
        righeFastBooking: cloneRigheF,
      };
    case types.CART_REMOVE_BUONO:
      //debugger;
      const cloneRigheB = [...state.righeBuoni];
      cloneRigheB.splice(action.index, 1);

      return {
        ...state,
        righeBuoni: cloneRigheB,
      };
    case types.APP_CLEAR:
      return {
        righePrivate: [],
        righeCollettive: [],
        righeFastBooking: [],
        righeBuoni: [],
      };

    case types.CART_EMPTY:
      return {
        righePrivate: [],
        righeCollettive: [],
        righeFastBooking: [],
        righeBuoni: [],
      };
    default:
      return state;
  }
}
