import React, { useState, useEffect } from "react";
import { DayPickerSingleDateController } from "react-dates";
import { Segment } from "semantic-ui-react";
import * as calendarUtils from "../../../utils/calendarUtils";
import styles from "./PacchettoCalendarioTipo1.module.css";
import moment from "moment";

const PacchettoCalendarioTipo1 = ({
  numGiorniScelti,
  sessioni,
  handleDataSelezionata,
  dateSelezionate,
  ...props
}) => {
  console.log(dateSelezionate);

  const [dataSelezionata, setDataSelezionata] = useState(null);
  const [focused, setFocused] = useState(false);
  const [giorniNoCorso, setGiorniNoCorso] = useState([]);

  useEffect(() => {
    if (numGiorniScelti < 1) return;

    const giorniNoCorsoTmp = [];

    for (
      var m = moment(sessioni.minDate);
      m.isBefore(sessioni.maxDate);
      m.add(1, "days")
    ) {
      if (
        !sessioni.sessioni[0].listaDateEffettive.some((s) =>
          calendarUtils.isSameDay(m, moment(s))
        )
      ) {
        giorniNoCorsoTmp.push(moment(m.format("YYYY-MM-DD")));
      }
    }
    setGiorniNoCorso(giorniNoCorsoTmp);
  }, [numGiorniScelti]);

  return (
    <Segment basic className={styles.calendario_container}>
      <DayPickerSingleDateController
        date={dataSelezionata}
        onDateChange={handleDataSelezionata}
        focused={focused}
        onFocusChange={({ focused }) => setFocused({ focused })}
        initialVisibleMonth={() => moment(sessioni.minDate)}
        isOutsideRange={(day) =>
          !calendarUtils.isInclusivelyAfterDay(day, moment(sessioni.minDate)) ||
          calendarUtils.isAfterDay(day, moment(sessioni.maxDate)) ||
          giorniNoCorso.some((day2) => calendarUtils.isSameDay(day, day2))
        }
        isDayHighlighted={(day) =>
          dateSelezionate.some((day2) => calendarUtils.isSameDay(day, day2))
        }
        numberOfMonths={2}
        hideKeyboardShortcutsPanel={true}
      />
    </Segment>
  );
};
export default PacchettoCalendarioTipo1;
