import { useParams, useHistory } from "react-router-dom";

function useSkiworkUrlCheck(appData) {
  const { lang } = useParams();
  const { slug } = useParams();
  const history = useHistory();

  if (
    lang.toLowerCase() !== appData.lang.toLowerCase() ||
    slug.toLowerCase() !== appData.slug.toLowerCase()
  ) {
    history.push(`/${lang}/${slug}`);
  }
}

export default useSkiworkUrlCheck;
