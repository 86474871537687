export function getDescrizioneSpecialita(specObj, idLingua) {
  let desc = "";
  switch (idLingua) {
    case 1:
      desc = specObj.titoloWebLang1;
      break;
    case 2:
      desc = specObj.titoloWebLang2;
      break;
    case 3:
      desc = specObj.titoloWebLang3;
      break;
    case 4:
      desc = specObj.titoloWebLang4;
      break;
    case 5:
      desc = specObj.titoloWebLang5;
      break;
    default:
      desc = specObj.descrSpecialita;
  }

  if (desc === undefined) return specObj.descrSpecialita;
  if (desc === null) return specObj.descrSpecialita;
  if (desc === "") return specObj.descrSpecialita;
  return desc;
}

// export function getLivelli(livelliSkiwork, livelloInternazionale) {
//   if (!livelloInternazionale) {
//     const livelliArray = livelliSkiwork.map((x, idx) => {
//       const el = {
//         key: x.id.toString(),
//         text: x.livello,
//         img: require(`../assets/images/livelli/${idx + 1}.jpg`),
//       };
//       return el;
//     });
//     return livelliArray;
//   } else {
//     const livelliArray = livelliSkiwork.map((x, idx) => {
//       const el = {
//         key: x.id.toString(),
//         text: x.livello,
//         img: require(`../assets/images/livelli/${idx + 1}+.jpg`),
//       };
//       return el;
//     });
//     return livelliArray;
//   }

export function getLivelli(datiScuola, idCorso) {
  const livelliFiltrati = [...datiScuola.tLivelli];

  if (idCorso !== undefined) {
    const livelliNascostiCorso = datiScuola.livelliNascosti.filter(
      (x) => x.idCorso === idCorso
    );
    for (let i = 0; i < livelliNascostiCorso.length; i++) {
      livelliFiltrati.splice(
        livelliFiltrati.findIndex(
          (x) => x.idLivelloIso === livelliNascostiCorso[i].idLivelloISO
        ),
        1
      );
    }
  }

  const livelliArray = livelliFiltrati.map((x, idx) => {
    const el = {
      key: x.id.toString(),
      text: x.livello,
      img: require(`../assets/images/livelli/${x.simbolo}.jpg`),
      infoString: x.descrizione,
    };

    return el;
  });

  return livelliArray;
}

export function getLivelliZona(datiScuola, idZona) {
  const livelliFiltrati = [...datiScuola.tLivelli];

  if (idZona !== undefined) {
    const livelliNascostiZona = datiScuola.livelliNascostiZona.filter(
      (x) => x.idZona === idZona
    );
    for (let i = 0; i < livelliNascostiZona.length; i++) {
      livelliFiltrati.splice(
        livelliFiltrati.findIndex(
          (x) => x.idLivelloIso === livelliNascostiZona[i].idLivelloISO
        ),
        1
      );
    }
  }

  const livelliArray = livelliFiltrati.map((x, idx) => {
    const el = {
      key: x.id.toString(),
      text: x.livello,
      img: require(`../assets/images/livelli/${x.simbolo}.jpg`),
      infoString: x.descrizione,
    };

    return el;
  });

  return livelliArray;
}
