export function ValidateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  //alert("You have entered an invalid email address!")
  return false;
}

export function cleanEmail(email) {
  // Regular expression to match valid email characters
  const regex = /[^a-zA-Z0-9!#$%&'*+/=?^_`{|}~@.-]/g;
  // Replace invalid characters with an empty string
  return email.replace(regex, "");
}
