import React from "react";
import { Header } from "semantic-ui-react";
import PropTypes from "prop-types";
import TipologiaServizio from "./TipologiaServizio";
import { useTranslation } from "react-i18next";

function ServiziCorso({ t, ...props }) {
  //const { i18n, t } = useTranslation();

  return (
    <>
      <Header as="h3">
        <Header.Content>{t("servizi")}</Header.Content>
        {props.serviziDisponibili.map((t) => (
          <TipologiaServizio
            tipoServizi={t}
            onServizioAggiunto={props.onServizioAggiunto}
            serviziAttivi={props.serviziAttivi}
            numAllievo={props.numAllievo}
            numGiorni={props.numGiorni}
            key={t.idTipologia}
            datiScuola={props.datiScuola}
          />
        ))}
      </Header>
    </>
  );
}

ServiziCorso.propTypes = {
  numAllievo: PropTypes.number.isRequired,
  numGiorni: PropTypes.number.isRequired,
  serviziDisponibili: PropTypes.array.isRequired,
  serviziAttivi: PropTypes.array.isRequired,
  onServizioAggiunto: PropTypes.func.isRequired,
};

export default ServiziCorso;
