import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Image, Segment, Icon, Label, Menu, Input } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../utils/useWindowSize";
import * as cartActions from "../../redux/actions/cartActions";
import * as appActions from "../../redux/actions/appActions";
import * as scuolaApi from "../../api/scuolaApi";
import LingueMobileButton from "../LingueMobileButton";

function getIdLinguaFromIso(codIso) {
  switch (codIso) {
    case "IT":
      return 1;
    case "DE":
      return 2;
    case "EN":
      return 3;
    case "GB":
      return 3;
    case "FR":
      return 4;
    case "ES":
      return 5;
    case "PL":
      return 6;
    case "RU":
      return 7;
    case "NL":
      return 8;
    case "CN":
      return 9;
    case "CZ":
      return 10;
    case "HR":
      return 11;
    case "PO":
      return 12;
    case "BR":
      return 13;
    case "AR":
      return 14;
    case "GR":
      return 15;
    case "DK":
      return 16;
    case "HU":
      return 17;
    case "SK":
      return 18;
    case "SE":
      return 19;
    case "NO":
      return 20;
    case "FI":
      return 21;
    case "JP":
      return 22;
    case "SI":
      return 23;
    case "UA":
      return 24;
    case "TR":
      return 25;
    default:
      return 1;
  }

  return 1;
}

function Testata({
  datiScuola,
  carrello,
  appData,
  setReferrer,
  setAgenzia,
  clearAppData,
  cartEmpty,
  bookReset,
  lang,
  ...props
}) {
  /////////////////////////////
  //STATE
  const [couponCode, setCouponCode] = useState("");

  ////////////////////////////
  //HOOKS
  useEffect(() => {}, []);
  const history = useHistory();
  const location = useLocation();
  const windowSize = useWindowSize();
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);

  async function checkCoupon() {
    var res = await scuolaApi.getAgenzia(datiScuola.id, couponCode);
    if (res === null) {
      alert(t("CODICE_NON_VALIDO"));
    } else {
      //setAgenzia(res);
      alert(t("BENVENUTO") + " " + res.nomeAgenzia);

      cartEmpty();
      setAgenzia(res);
      history.push(`/${appData.lang}/${appData.slug}`);
    }
  }

  if (
    datiScuola.codiceScuola &&
    datiScuola.codiceScuola.length === 8 &&
    carrello !== undefined
  ) {
    const logoImgPath = `scuole/${datiScuola.codiceScuola}/logo.png`;

    return (
      <div className="testata">
        {windowSize.width < 600 && <LingueMobileButton></LingueMobileButton>}
        <Segment basic className="testata">
          <div style={{ float: "left" }}>
            <Image
              className="testata-logo"
              src={logoImgPath}
              size="small"
              onClick={() => {
                history.back();
              }}
            ></Image>
          </div>
          <div style={{ position: "absolute", right: "24px", top: "5px" }}>
            {windowSize.width > 600 && datiScuola.lingue !== undefined && (
              <Menu style={{ padding: "0px" }}>
                {datiScuola.lingue
                  .filter((l) => !l.soloLinguaCliente)
                  .map((x) => {
                    return (
                      <Menu.Item
                        key={x.idskiwork}
                        active={x.idskiwork === datiScuola.idLinguaInUso}
                        style={{
                          cursor: "pointer",
                          paddingTop: "0px",
                          paddingRight: "5px",
                          paddingBottom: "0px",
                          paddingLeft: "5px",
                        }}
                        onClick={() => {
                          const newCode = x.codiceIso.toLowerCase();

                          if (
                            location.pathname.toLowerCase().includes("checkin")
                          ) {
                            history.push(`/${newCode}/${appData.slug}/checkin`);
                            history.go(0);
                          } else {
                            history.push(`/${newCode}/${appData.slug}`);
                          }
                        }}
                        className="skiwork-lang-menuitem"
                      >
                        <img
                          src={require(`../../assets/images/flags/noborder/${getIdLinguaFromIso(
                            x.codiceIso.toUpperCase()
                          )}.jpg`)}
                          alt={x.codiceIso.toLowerCase()}
                        ></img>
                      </Menu.Item>
                    );
                  })}
              </Menu>
            )}
          </div>
          <div
            style={{
              position: "absolute",
              right: "24px",
              bottom: "5px",
            }}
          >
            {windowSize.width > 600 && (
              <Menu compact style={{}}>
                {datiScuola.agenzie && appData.agenzia === undefined && (
                  <Menu.Item style={{}}>
                    <Input
                      label="coupon code"
                      action={{
                        icon: "search",
                        onClick: async () => {
                          await checkCoupon();
                        },
                      }}
                      value={couponCode}
                      onChange={(ev, data) => {
                        setCouponCode(data.value);
                      }}
                    ></Input>
                  </Menu.Item>
                )}
                {datiScuola.agenzie && appData.agenzia !== undefined && (
                  <Menu.Item>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setAgenzia(undefined);
                        setCouponCode("");
                        bookReset();
                        cartEmpty();
                        history.push(`/${appData.lang}/${appData.slug}`);
                      }}
                    >
                      <Icon name="x" size="large" />
                    </span>
                    <span>
                      {t("LISTINO_PER")}
                      {appData.agenzia.nomeAgenzia}
                    </span>
                  </Menu.Item>
                )}
                <Menu.Item
                  as="a"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <Icon size="big" name="arrow left" />
                </Menu.Item>
                <Menu.Item
                  as="a"
                  onClick={() => {
                    //clearAppData();
                    history.push(`/${appData.lang}/${appData.slug}`);
                  }}
                >
                  <Icon size="big" name="home" />
                </Menu.Item>
                <Menu.Item
                  as="a"
                  onClick={() => {
                    setReferrer("");
                    history.push(`/${appData.lang}/${appData.slug}/cart`);
                  }}
                >
                  <Icon name="cart" size="big" />
                  {(carrello.righeCollettive.length > 0 ||
                    carrello.righePrivate.length > 0 ||
                    carrello.righeFastBooking.length > 0 ||
                    carrello.righeBuoni.length > 0) && (
                    <Label color="yellow" floating size="big">
                      {carrello.righeCollettive.length +
                        carrello.righePrivate.length +
                        carrello.righeFastBooking.length +
                        carrello.righeBuoni.length}
                    </Label>
                  )}
                </Menu.Item>
              </Menu>
            )}
          </div>
        </Segment>
      </div>
    );
  } else {
    return <></>;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    carrello: state.cartReducer,
  };
}

const mapDispatchToProps = {
  setReferrer: cartActions.setReferrer,
  setAgenzia: appActions.setAgenzia,
  clearAppData: appActions.clearAppData,
  bookReset: appActions.bookReset,
  cartEmpty: cartActions.cartEmpty,
};

export default connect(mapStateToProps, mapDispatchToProps)(Testata);
