import React from "react";
import { Form, Icon, Button } from "semantic-ui-react";
import styles from "./PacchettoDettagliTipo2.module.css";
import moment from "moment";

const PacchettoDettagliTipo2 = ({
  t,
  numGiorniDisponibili,
  numGiorniScelti,
  numAllieviDisponibili,
  numAllievi,
  handleNumGiorniChange,
  handleDataSelezionata,
  setNumAllievi,
  righeSelezionate,
  ...props
}) => {
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Dropdown
          selection
          label={t("numero_giorni")}
          options={numGiorniDisponibili}
          value={numGiorniScelti}
          onChange={(e, data) => {
            handleNumGiorniChange(data.value);
          }}
        ></Form.Dropdown>
        {numGiorniScelti > 0 && (
          <Form.Dropdown
            selection
            label={t("numero_allievi")}
            options={numAllieviDisponibili}
            value={numAllievi}
            onChange={(e, data) => {
              setNumAllievi(data.value);
            }}
          ></Form.Dropdown>
        )}
      </Form.Group>
      {righeSelezionate.length > 0 && (
        <>
          <div className="field">
            <label>Programma scelto</label>

            {righeSelezionate.map((r) => (
              <>
                <div className={styles.programma_container}>
                  <span>{r.rigaStampa}</span>
                  <Icon
                    name="trash"
                    color="red"
                    size="large"
                    onClick={() => {
                      handleDataSelezionata(moment(r.data));
                    }}
                  />
                </div>
              </>
            ))}
            {righeSelezionate.length < numGiorniScelti && (
              <p className={styles.scegli_ancora}>
                Scegliere ancora {numGiorniScelti - righeSelezionate.length}{" "}
                giornate
              </p>
            )}
            <p></p>
          </div>
        </>
      )}
      {righeSelezionate.length === 0 && numGiorniScelti > 0 && (
        <div className={styles.seleziona_data}>
          <Icon name="arrow left" size="large" />
          <span>SCEGLI LE DATE</span>
        </div>
      )}
    </Form>
  );
};

export default PacchettoDettagliTipo2;
