import React from "react";
import { Icon, Header, Form, Segment } from "semantic-ui-react";
import Prezzo from "../Prezzo";

const PacchettoDettagliTipo0 = ({
  t,
  numGiorniDisponibili,
  numGiorniScelti,
  numAllieviDisponibili,
  numAllievi,
  corso,
  sessioniCorso,
  datiScuola,
  handleNumGiorniChange,
  setNumAllievi,
  ...props
}) => {
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Dropdown
          selection
          label={t("numero_giorni")}
          options={numGiorniDisponibili}
          value={numGiorniScelti}
          onChange={(e, data) => {
            handleNumGiorniChange(data.value);
          }}
        ></Form.Dropdown>
        <Form.Dropdown
          selection
          label={t("numero_allievi")}
          options={numAllieviDisponibili}
          value={numAllievi}
          onChange={(e, data) => {
            setNumAllievi(data.value);
          }}
        ></Form.Dropdown>
      </Form.Group>
    </Form>
  );
};

export default PacchettoDettagliTipo0;
