import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import escursioni_it from "./it/escursioni.json";
// import escursioni_en from "./en/escursioni.json";
// import escursioni_de from "./de/escursioni.json";

import Backend from "i18next-xhr-backend";

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,

    lng: "it",
    fallbackLng: "en",
    whitelist: ["en", "de", "it", "fr"],

    ns: ["translation", "escursioni", "playasd"],
    defaultNS: "translation",
    fallbackNS: "translation",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
