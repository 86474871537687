import React from "react";
import { Segment, Card, CardContent } from "semantic-ui-react";
import PropTypes from "prop-types";
import SchedaCorsoCard from "./SchedaCorsoCard";
import { useTranslation } from "react-i18next";

function GrigliaCorsi({ t, ...props }) {
  //const { t, i18n } = useTranslation();

  if (props.listaCorsi === undefined || props.listaCorsi.length === 0) {
    return (
      <Segment className="contenitore-grande">
        NESSUN CORSO DISPONIBILE DEL TIPO SELEZIONATO
      </Segment>
    );
  }
  return (
    <Segment style={{ marginBottom: "30px" }} className="contenitore-grande">
      <Segment inverted className="skiwork-header" textAlign="center">
        {t("corsi_disponibili")}
      </Segment>
      <Card.Group centered style={{ padding: "0px" }}>
        {props.listaCorsi.map((c) => {
          return (
            <SchedaCorsoCard
              codScuola={
                !props.codPrimario ? props.codScuola : props.codPrimario
              }
              corso={c}
              onSelect={props.onCorsoSelezionato}
              isVisible={true} //{cardVisibility[c.id]}
              key={c.idCorso}
              t={t}
            ></SchedaCorsoCard>
          );
        })}
      </Card.Group>
    </Segment>
  );
}

GrigliaCorsi.propTypes = {
  listaCorsi: PropTypes.array,
  codScuola: PropTypes.string.isRequired,
  onCorsoSelezionato: PropTypes.func.isRequired,
};

export default GrigliaCorsi;
