import React, { useState, useEffect } from "react";
import { Segment, Card, Icon } from "semantic-ui-react";
import SchedaCategoriaCard from "./SchedaCategoriaCard";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function ListaCategorieGrafica({ t, ...props }) {
  const [isOpen, setIsOpen] = useState(true);
  const [showChevron, setShowChevron] = useState(false);
  //const { t, i18n } = useTranslation();

  useEffect(() => {
    if (props.idTipologia !== undefined && props.idTipologia > 0) {
      setIsOpen(false);
      setShowChevron(true);
    }
  }, [props.idTipologia]);

  function handleHeadClick() {
    if (showChevron) {
      setIsOpen(!isOpen);
    }
  }

  return (
    <Segment className="padding_0_lr contenitore-grande">
      <Segment
        inverted
        className="skiwork-header"
        textAlign="center"
        onClick={handleHeadClick}
      >
        {t("tipo_corso")}
        <Icon
          style={{
            float: "right",
            display: showChevron ? "block" : "none",
            cursor: "pointer",
          }}
          name={isOpen ? "chevron up" : "chevron down"}
          size="large"
        ></Icon>
      </Segment>
      <Card.Group
        centered
        className="padding_5_lr"
        style={{ display: isOpen ? "flex" : "none" }}
      >
        {props.tipologie.map((tipo) => {
          return (
            <SchedaCategoriaCard
              codScuola={
                !props.codPrimario ? props.codScuola : props.codPrimario
              }
              tipologia={tipo}
              onSelect={props.onTipologiaChange}
              isSelected={tipo.idTipologiaCorsoWeb === props.idTipologia}
              key={t.idTipologiaCorsoWeb}
              t={t}
            ></SchedaCategoriaCard>
          );
        })}
      </Card.Group>
    </Segment>
  );
}

ListaCategorieGrafica.propTypes = {
  codScuola: PropTypes.string.isRequired,
  tipologie: PropTypes.array.isRequired,
  idTipologia: PropTypes.number,
  stileVisualizzazione: PropTypes.string.isRequired,
  onTipologiaChange: PropTypes.func.isRequired,
};

export default ListaCategorieGrafica;
