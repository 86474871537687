import { handleResponse, handleError, getBaseUrl } from "./apiUtils";

//const baseUrl = process.env.API_URL + "/clubs/";
const baseUrl = getBaseUrl(); //"https://localhost:44354/api/";

export async function getListaCorsi(idScuola, data, type, lang, idAgenzia) {
  return fetch(
    `${baseUrl}scuole/${idScuola}/corsi?data=${encodeURI(
      data
    )}&type=${type}&lang=${lang}&idAgenzia=${idAgenzia}`
  ) //baseUrl + "scuole/" + codScuola + "/corsi?data=")
    .then(handleResponse)
    .catch(handleError);
}

export async function getListaSessioni(
  idScuola,
  idCorso,
  data,
  lang,
  idAgenzia
) {
  // const s = `${baseUrl}scuole/${idScuola}/corsi/${idCorso}/sessioni?data=${encodeURI(
  //   data
  // )}&idAgenzia=${idAgenzia}&lang=${lang}`;

  return fetch(
    `${baseUrl}scuole/${idScuola}/corsi/${idCorso}/sessioni?data=${encodeURI(
      data
    )}&idAgenzia=${idAgenzia}&lang=${lang}`
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getServiziDisponibili(
  idScuola,
  idCorso,
  data,
  lang,
  idAgenzia
) {
  return fetch(
    `${baseUrl}scuole/${idScuola}/corsi/${idCorso}/servizi?data=${encodeURI(
      data
    )}&idAgenzia=${idAgenzia}&lang=${lang}`
  )
    .then(handleResponse)
    .catch(handleError);
}
