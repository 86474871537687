import * as types from "./actionTypes";
import * as corsiApi from "../../api/corsiApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

export function loadCorsiSuccess(listaCorsi) {
  return {
    type: types.LOAD_CORSI_SUCCESS,
    listaCorsi,
  };
}

export function loadSessioniSuccess(listaSessioni) {
  return {
    type: types.LOAD_SESSIONICORSI_SUCCESS,
    listaSessioni,
  };
}

// export function loadServiziSuccess(listaServizi) {
//   const ser = listaServizi.listaTipologie;
//   return {
//     type: types.LOAD_SERVIZICORSO_SUCCESS,
//     servizi: ser,
//   };
// }

export function loadCorsi(idScuola, data, type, lang) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return corsiApi
      .getListaCorsi(idScuola, data, type, lang)
      .then((corsi) => {
        dispatch(loadCorsiSuccess(corsi));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadSessioni(idScuola, idCorso, data, idAgenzia, lang) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return corsiApi
      .getListaSessioni(idScuola, idCorso, data, lang, idAgenzia)
      .then((sessioni) => {
        dispatch(loadSessioniSuccess(sessioni));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

// export function loadServizi(idScuola, idCorso, data, idAgenzia, lang) {
//   return function (dispatch) {
//     dispatch(beginApiCall());
//     return corsiApi
//       .getServiziDisponibili(idScuola, idCorso, data, lang, idAgenzia)
//       .then((servizi) => {
//         dispatch(loadServiziSuccess(servizi));
//       })
//       .catch((error) => {
//         dispatch(apiCallError(error));
//         throw error;
//       });
//   };
// }

export function clearData() {
  return {
    type: types.APP_CLEAR,
  };
}
