import { handleResponse, handleError, getBaseUrl } from "./apiUtils";
//const baseUrl = process.env.API_URL + "/clubs/";
const baseUrl = getBaseUrl(); //"https://localhost:44354/api/";

export function getDatiScuola(idClub, lang) {
  console.log("LOADING SCHOOL DATA");
  return fetch(`${baseUrl}scuole/${idClub}?lang=${lang}`)
    .then(handleDatiScuolaResponse)
    .catch(handleError);
}

async function handleDatiScuolaResponse(response) {
  if (response.ok) return response.json();
  window.location.href = "/";
}

export function getNazioni(idClub) {
  return fetch(`${baseUrl}scuole/${idClub}/nazioni`)
    .then(handleResponse)
    .catch(handleError);
}

export async function getAgenzia(idScuola, couponCode) {
  return fetch(`${baseUrl}scuole/${idScuola}/agenzia?couponCode=${couponCode}`)
    .then(handleResponse)
    .catch(handleError);
}
