import { getBaseUrl, handleResponse } from "./apiUtils";

const baseUrl = getBaseUrl();

export async function GetTipiBuono(idScuola, lingua, codScuola) {
  const res = await fetch(
    `${baseUrl}buoni/tipi?idscuola=${idScuola}&lingua=${lingua}&codScuola=${codScuola}`,
    {
      method: "GET",
    }
  );

  return handleResponse(res);
}

export async function GetInfoBuono(idScuola, codice, download) {
  if (download === undefined) {
    download = false;
  }
  const res = await fetch(
    `${baseUrl}buoni/${codice}?idScuola=${idScuola}&download=${download}`,
    {
      method: "GET",
    }
  );

  return handleResponse(res);
}
