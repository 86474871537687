import React, { useState, useEffect } from "react";
import { Button, Segment, Card, Image, Loader, Flag } from "semantic-ui-react";
import * as privateApi from "../../api/privateApi";
import PropTypes from "prop-types";
import defaultImgUomo from "../../assets/images/people/AvatarMaestro.jpg";
import defaultImgDonna from "../../assets/images/people/AvatarMaestra.jpg";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../utils/useWindowSize";

function GrigliaMaestri({ t, ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const [listaMaestri, setListaMaestri] = useState([]);
  //const { i18n, t } = useTranslation();

  function getIdLinguaFromIso(codIso) {
    switch (codIso) {
      case "IT":
        return 1;
      case "DE":
        return 2;
      case "EN":
        return 3;
      case "GB":
        return 3;
      case "FR":
        return 4;
      case "ES":
        return 5;
      case "PL":
        return 6;
      case "RU":
        return 7;
      case "NL":
        return 8;
      case "CN":
        return 9;
      case "CZ":
        return 10;
      case "HR":
        return 11;
      case "PO":
        return 12;
      case "BR":
        return 13;
      case "AR":
        return 14;
      case "GR":
        return 15;
      case "DK":
        return 16;
      case "HU":
        return 17;
      case "SK":
        return 18;
      case "SE":
        return 19;
      case "NO":
        return 20;
      case "FI":
        return 21;
      case "JP":
        return 22;
      case "SI":
        return 23;
      case "UA":
        return 24;
      case "TR":
        return 25;
      default:
        return 1;
    }

    return 1;
  }

  const windowSize = useWindowSize();

  useEffect(() => {
    async function fetchMaestri() {
      setIsLoading(true);
      setListaMaestri(await privateApi.getMaestri(props.idScuola));
    }
    fetchMaestri();
    setIsLoading(false);
  }, []);

  function addDefaultSrc(ev) {
    if (ev.target.alt === "M") ev.target.src = defaultImgUomo;
    else {
      ev.target.src = defaultImgDonna;
    }
  }

  function renderMain() {
    return (
      <Card.Group centered itemsPerRow={windowSize.width > 600 ? "6" : "2"}>
        {listaMaestri.map((maestro) => (
          <Card key={maestro.id} className="schedaCorsoCard">
            {/* <Image
              wrapped
              ui={false}
              size="tiny"
              src={`https://skiwork.blob.core.windows.net/webimg/${props.codScuola.toUpperCase()}/Maestri/${
                maestro.picture
              }`}
              onError={addDefaultSrc}
              alt={maestro.sesso}
            ></Image> */}
            <div
              style={{
                width: "100%",
                height: "0",
                paddingBottom: "130%",
                position: "relative",
              }}
              className={"skiwork-image-maestri-container"}
            >
              <img
                onError={addDefaultSrc}
                src={`https://skiwork.blob.core.windows.net/webimg/${props.codScuola.toUpperCase()}/Maestri/${
                  maestro.picture
                }`}
                alt={maestro.sesso}
                className="skiwork-image"
                width="100%"
                height="100%"
              ></img>
            </div>

            <Card.Content style={{ border: "0" }}>
              <Card.Header
                as="h3"
                textAlign="center"
                className="skiwork-card-header2 skiwork-card-header"
              >
                {maestro.titoloWeb}
              </Card.Header>
              <Card.Meta
                textAlign="center"
                className="skiwork-card-subheader2 skiwork-card-subheader"
              >
                {maestro.specialitaDesc.map(
                  (s) => s.length > 0 && <span key={s}>{s}</span>
                )}
              </Card.Meta>
              <Card.Description textAlign="center">
                <div style={{ textAlign: "center", margin: "0 auto" }}>
                  {maestro.lingueDesc.map(
                    (l) =>
                      l.length > 0 && (
                        <img
                          key={l}
                          style={{
                            width: "2.3rem",
                            marginLeft: "3px",
                            marginRight: "3px",
                          }}
                          src={require(`../../assets/images/flags/noborder/${getIdLinguaFromIso(
                            l.toUpperCase()
                          )}.jpg`)}
                          alt={l.toLowerCase()}
                        ></img>
                      )
                    // <Flag name={l.toLowerCase()}></Flag>
                  )}
                </div>
              </Card.Description>

              <Card.Meta
                textAlign="center"
                style={{ marginTop: "1rem", fontWeight: "100" }}
                className="skiwork-card-subheader2 skiwork-card-subheader"
              >
                {maestro.descWeb}
              </Card.Meta>
            </Card.Content>
            <Card.Content
              style={{ marginBottom: "30px", border: "0" }}
              textAlign="center"
            >
              <Segment
                style={{
                  position: "absolute",
                  bottom: "0px",
                  top: "auto",
                  left: "0",
                  right: "0",
                }}
                basic
                textAlign="center"
              >
                <Button
                  primary
                  onClick={() => {
                    props.onSelect(maestro.idSkiwork, maestro);
                  }}
                  disabled={!maestro.specialita.includes(props.idSpecialita)}
                >
                  {/* <Icon name="cart"></Icon> */}
                  {t("scegli")}
                </Button>
              </Segment>
            </Card.Content>
          </Card>
        ))}
      </Card.Group>
    );
  }

  return <>{isLoading ? <Loader /> : renderMain()}</>;
}

GrigliaMaestri.propTypes = {
  codScuola: PropTypes.string.isRequired,
  idScuola: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  idSpecialita: PropTypes.number.isRequired,
};

export default GrigliaMaestri;
