import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, Segment, Header } from "semantic-ui-react";
import TestataPagina from "../common/TestataPagina";
import { useTranslation } from "react-i18next";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as appActions from "../../redux/actions/appActions";

const CodeError = ({ appData, datiScuola }) => {
  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  return (
    <Container>
      <div className="head-offset"></div>
      <TestataPagina
        titolo="PAGAMENTO"
        sottoTitolo="Pagamento NON a buon fine!"
        icon="credit card"
        style={{ backgroundColor: "red" }}
      ></TestataPagina>
      <Segment className="padding_5_lr">
        <Segment inverted color="red">
          <Header as="h3" textAlign="center">
            IL LINK NON E' CORRETTO OPPURE LA RICHIESTA DI PAGAMENTO E' SCADUTA
          </Header>
        </Segment>
      </Segment>
    </Container>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadSchoolData: scuolaActions.loadSchoolData,
  setSlug: appActions.setSlug,
  setLang: appActions.setLang,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeError);
