import React, { useEffect, useState } from "react";
import { Segment, Form, Header, Grid, Divider, Label } from "semantic-ui-react";
import PropTypes from "prop-types";
import ServiziCorso from "./ServiziCorso";

import moment from "moment";
import GruppoSelezione from "../common/GruppoSelezione";
import useWindowSize from "../../utils/useWindowSize";
import FormError from "../../components/common/FormError";
import * as skiworkUtilis from "../../utils/skiworkUtils";
import { useTranslation } from "react-i18next";

function SchedaAllievoCorso({ datiAllievo, datiScuola, datiCorso, ...props }) {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [data, setData] = useState(null);
  const [nascondiLivello, setNascondiLivello] = useState(false);
  // const [selezioneAutomaticaLivello, setSelezioneAutomaticaLivello] =
  //   useState(false);
  const [anniNascita, setAnniNascita] = useState([]);
  const [opzioniSesso] = useState([
    {
      key: "M",
      text: "M",
      img: require(`../../assets/images/people/Male2.jpg`),
    },
    {
      key: "F",
      text: "F",
      img: require(`../../assets/images/people/Female2.jpg`),
    },
  ]);

  const [livelli] = useState(
    skiworkUtilis.getLivelli(datiScuola, datiCorso.idCorso)
  );
  const [dataNascita, setDataNascita] = useState(null);
  const [dataNascitaFocused, setDataNascitaFocused] = useState(false);
  // const [datiAllievo, setDatiAllievo] = useState({});
  const windowSize = useWindowSize();

  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  useEffect(() => {
    const currentYear = moment().year();
    const minEta = datiCorso.minAnni;
    const maxEta = datiCorso.maxAnni;

    const tempArray = new Array(maxEta - minEta);

    for (
      let year = currentYear - minEta;
      year >= currentYear - maxEta;
      year--
    ) {
      tempArray[currentYear - minEta - year] = {
        key: year,
        text: year,
        value: year,
      };
    }
    setAnniNascita(tempArray);

    datiAllievo.Index = props.num - 1;
  }, []);

  useEffect(() => {
    if (livelli === undefined) {
      return;
    }

    if (livelli.length === 0) {
      setNascondiLivello(true);
    }
    if (livelli.length === 1) {
      props.onChange(props.num, "livello", livelli[0].key);
    }
  }, [livelli]);

  function handleFieldChange(e, value) {
    props.onChange(props.num, value.name, value.value);
  }

  function isValidDate(year, month, day) {
    var d = new Date(year, month, day);
    if (
      d.getFullYear() === year &&
      d.getMonth() === month &&
      d.getDate() === day
    ) {
      return true;
    }
    return false;
  }

  function handleDataNascitaChange(val) {
    let newData = null;

    if (val.target.name === "giorno") {
      const re = /^[0-9\b]+$/;
      if (val.target.value !== "" && !re.test(val.target.value)) {
        val.target.value = day;
        return;
      }
    }

    if (val.target.name === "mese") {
      const re = /^[0-9\b]+$/;
      if (val.target.value !== "" && !re.test(val.target.value)) {
        val.target.value = month;
        return;
      }
    }

    if (val.target.name === "anno") {
      const re = /^[0-9\b]+$/;
      if (val.target.value !== "" && !re.test(val.target.value)) {
        val.target.value = year;
        return;
      }
    }

    switch (val.target.name) {
      case "giorno":
        if (val.target.value > 31) {
          return;
        }
        setDay(val.target.value);
        newData = new Date(val.target.value, month - 1, day, 8, 0, 0, 0);
        setData(newData);

        if (year === "" || month === "")
          props.onChange(props.num, "dataNascita", null);
        else props.onChange(props.num, "dataNascita", newData);
        break;
      case "mese":
        if (val.target.value > 12) {
          return;
        }

        setMonth(val.target.value);
        //controllo il giorno
        if (day > 28) {
          if (year > 1800) {
            if (!isValidDate(year, val.target.value - 1, day)) {
              setDay("");
              return;
            }
          }
        }

        newData = new Date(val.target.value, month - 1, day, 8, 0, 0, 0);
        setData(newData);
        if (day === "" || year === "")
          props.onChange(props.num, "dataNascita", null);
        else props.onChange(props.num, "dataNascita", newData);
        break;
      case "anno":
        if (val.target.value > new Date().getFullYear()) {
          return;
        }
        setYear(val.target.value);

        newData = new Date(val.target.value, month - 1, day, 8, 0, 0, 0);
        setData(newData);
        if (day === "" || month === "") {
          props.onChange(props.num, "dataNascita", null);
        } else {
          props.onChange(props.num, "dataNascita", newData);
        }
        break;
      default:
    }
  }

  function handleLivelloChange(key) {
    props.onChange(props.num, "livello", key);
  }

  function handleSessoChange(key) {
    props.onChange(props.num, "sesso", key);
  }

  return (
    <Segment basic className="padding_0_lr">
      <Header>
        <Header.Content as="h2" className="skiwork-titolo">
          {t("allievo")} {props.num}
        </Header.Content>
      </Header>
      <Divider />

      <Grid columns="equal">
        <Grid.Column>
          <Segment
            basic
            style={
              windowSize.width < 600
                ? { paddingLeft: "0px", paddingRight: "0px" }
                : {}
            }
          >
            <Form>
              <Form.Input
                label={t("nome")}
                value={datiAllievo.nome}
                name="nome"
                onChange={handleFieldChange}
                fluid
                error={
                  props.errors.nome
                    ? {
                        content: t("nome_error"),
                        pointing: "below",
                      }
                    : null
                }
              />
              <Form.Input
                label={t("cognome")}
                value={datiAllievo.cognome}
                name="cognome"
                onChange={handleFieldChange}
                fluid
                error={
                  props.errors.cognome
                    ? {
                        content: t("cognome_error"),
                        pointing: "below",
                      }
                    : null
                }
              />
              {datiScuola.dataLuogoNascita === true && (
                <>
                  <Form.Input
                    fluid
                    label={"Luogo di nascita"}
                    width={10}
                    placeholder={"Città (Provincia)"}
                    name="luogoNascita"
                    onChange={handleFieldChange}
                    value={datiAllievo.luogoNascita}
                    error={
                      props.errors.luogoNascita
                        ? {
                            content: "Luogo di nascita non corretto", //t("cf_error"),
                            pointing: "below",
                          }
                        : null
                    }
                  ></Form.Input>
                  <Form.Group
                    style={{ marginBottom: "7px", marginLeft: "0px" }}
                  >
                    <Label
                      style={{
                        paddingBottom: "0px",
                        paddingLeft: "1px",
                        fontSize: "14px",
                      }}
                    >
                      Data di nascita
                    </Label>
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      width={2}
                      label="Giorno"
                      name="giorno"
                      placeholder="GG"
                      value={day}
                      onChange={handleDataNascitaChange}
                      error={
                        props.errors.dataNascita
                          ? {
                              content: "DATA DI NASCITA NON CORRETTA",
                              pointing: "below",
                            }
                          : null
                      }
                    ></Form.Input>
                    <Form.Input
                      width={2}
                      label="Mese"
                      name="mese"
                      placeholder="MM"
                      value={month}
                      onChange={handleDataNascitaChange}
                      error={
                        props.errors.dataNascita
                          ? {
                              content: "DATA DI NASCITA NON CORRETTA",
                              pointing: "below",
                            }
                          : null
                      }
                    ></Form.Input>
                    <Form.Input
                      width={3}
                      label="Anno"
                      name="anno"
                      placeholder="AAAA"
                      value={year}
                      onChange={handleDataNascitaChange}
                      error={
                        props.errors.dataNascita
                          ? {
                              content: "DATA DI NASCITA NON CORRETTA",
                              pointing: "below",
                            }
                          : null
                      }
                    ></Form.Input>
                  </Form.Group>
                </>
              )}
              {datiScuola.dataLuogoNascita !== true && (
                <Form.Select
                  fluid
                  label={t("anno_nascita")}
                  options={anniNascita}
                  placeholder={t("anno_nascita")}
                  name="anno"
                  value={datiAllievo.AnnoNascita}
                  onChange={handleFieldChange}
                  error={
                    props.errors.anno
                      ? {
                          content: t("anno_error"),
                          //pointing: "below",
                        }
                      : null
                  }
                />
              )}
              <GruppoSelezione
                titolo={t("sesso")}
                elementi={opzioniSesso}
                width={97}
                onChange={handleSessoChange}
                selezione={datiAllievo.sesso}
              ></GruppoSelezione>
              {props.errors.sesso && (
                <FormError
                  errore="SELEZIONARE IL SESSO DELL'ALLIEVO"
                  pointingDirection={"above"}
                ></FormError>
              )}
              {!nascondiLivello && props.errors.livello && (
                <FormError
                  errore="SELEZIONARE IL LIVELLO DELL'ALLIEVO"
                  pointingDirection={"below"}
                ></FormError>
              )}
              {!nascondiLivello && (
                <GruppoSelezione
                  titolo={t("livello")}
                  elementi={livelli}
                  width={97}
                  onChange={handleLivelloChange}
                  selezione={datiAllievo.livello}
                ></GruppoSelezione>
              )}
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column mobile="16" largeScreen="7">
          {props.serviziDisponibili.length > 0 && (
            <Segment style={{ backgroundColor: "white" }}>
              <ServiziCorso
                t={t}
                serviziDisponibili={props.serviziDisponibili}
                serviziAttivi={props.serviziAttivi}
                onServizioAggiunto={props.onServizioAggiunto}
                numAllievo={props.num}
                numGiorni={props.numGiorni}
                datiScuola={datiScuola}
              />
            </Segment>
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  );
}

SchedaAllievoCorso.propTypes = {
  num: PropTypes.number.isRequired,
  datiAllievo: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  numGiorni: PropTypes.number.isRequired,
  datiScuola: PropTypes.object.isRequired,
  datiCorso: PropTypes.object.isRequired,
  serviziDisponibili: PropTypes.array.isRequired,
  serviziAttivi: PropTypes.array.isRequired,
  livelli: PropTypes.array,
  onServizioAggiunto: PropTypes.func.isRequired,
};

export default SchedaAllievoCorso;
